import React, { useEffect, useState } from 'react';

const LoadingSpinner = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000); // Hide after 3 seconds

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  if (!visible) return null; // Do not render if not visible

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="flex flex-col items-center gap-8">
        <div className="relative w-32 h-16">
          <div className="flex gap-2">
            {/* Left block with flipping R/Q */}
            <div className="relative w-14 h-14 animate-bounce">
              <div className="absolute inset-0 animate-flip">
                <div className="absolute inset-0 w-full h-full bg-blue-400 rounded-lg flex items-center justify-center backface-hidden">
                  <span className="text-white font-bold text-2xl">R</span>
                </div>
                <div className="absolute inset-0 w-full h-full bg-blue-600 rounded-lg flex items-center justify-center backface-hidden rotate-y-180">
                  <span className="text-white font-bold text-2xl">R</span>
                </div>
              </div>
            </div>
            {/* Right block with flipping Q/R */}
            <div className="relative w-14 h-14 animate-bounce delay-150">
              <div className="absolute inset-0 animate-flip-delay">
                <div className="absolute inset-0 w-full h-full bg-blue-600 rounded-lg flex items-center justify-center backface-hidden">
                  <span className="text-white font-bold text-2xl">Q</span>
                </div>
                <div className="absolute inset-0 w-full h-full bg-blue-400 rounded-lg flex items-center justify-center backface-hidden rotate-y-180">
                  <span className="text-white font-bold text-2xl">Q</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Loading dots */}
        <div className="flex space-x-2">
          <div className="w-3 h-3 bg-blue-400 rounded-full animate-bounce"></div>
          <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce delay-100"></div>
          <div className="w-3 h-3 bg-blue-600 rounded-full animate-bounce delay-200"></div>
        </div>
      </div>
    </div>
  );
};

const style = document.createElement('style');
style.textContent = `
  .backface-hidden {
    backface-visibility: hidden;
  }
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
  @keyframes flip {
    0%, 45% { transform: rotateY(0deg); }
    55%, 95% { transform: rotateY(180deg); }
    100% { transform: rotateY(0deg); }
  }
  @keyframes flipDelay {
    0%, 45% { transform: rotateY(180deg); }
    55%, 95% { transform: rotateY(360deg); }
    100% { transform: rotateY(180deg); }
  }
  .animate-flip {
    animation: flip 3s infinite;
    transform-style: preserve-3d;
  }
  .animate-flip-delay {
    animation: flipDelay 3s infinite;
    transform-style: preserve-3d;
  }
  .delay-100 {
    animation-delay: 100ms;
  }
  .delay-200 {
    animation-delay: 200ms;
  }
`;
document.head.appendChild(style);

export default LoadingSpinner;