import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import SharedProjectCard from "../components/RQPage/SharedProjectCard";
import RequestMore from "../components/RQPage/RequestMore";
import { CollaborationSection } from "../components/RQPage/CollaborationSection";
import Prdimage from "../assets/prdbg.png";
import Loader from "../assets/loader.gif";
import Updating from "../assets/updating.gif";
import Slideimage from "../assets/slidebg.png";
import Newtag from "../assets/new_tag.png";
import config from "../url_config/urls_config";
import Header from "../components/Common/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PRDPage from "./PRDPage";
import { ImCancelCircle } from "react-icons/im";
import Deck from "./Deck"; // Import Deck component
import FeedbackModal from "../components/RQPage/FeedbackModal";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Image } from "../components/RQPage/Image";
import debounce from "lodash.debounce";
import LiveKit from "./Livekit.tsx";
import SummaryModal from "../components/RQPage/SummaryModal"

const BASE_URL = config.getRq_api();
const RqPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {projid, uname, lroom_name, chat} = location.state || {};
  const { room_name = localStorage.getItem("room_name") } =
    location.state || {};
  // const [regenerate_prd, setRegeneratePrd] = useState(false);
  const { regenerate_prd, remainingTime, project_id, first } =
    location.state || {};
  const [deckProjId, setDeckProjId] = useState();
  const { name = localStorage.getItem("name") } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isPrdGenerating, setisPrdGenerating] = useState(false);
  const [isDeckGenerating, setisDeckGenerating] = useState(false);
  const [prdmodalopen, setprdmodalopen] = useState();
  const [projectDetails, setProjectDetails] = useState(null); // Store project details
  const [prdContent, setPrdContent] = useState(null);
  const [isDeckModalOpen, setIsDeckModalOpen] = useState(false); // State for modal
  const [sharedUsers, setSharedUsers] = useState([]);
  const [sharedProjects, setSharedProjects] = useState([]);
  const [activeSlideContent, setActiveSlideContent] = useState(null);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isRequestMoreOpen, setIsRequestMoreOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [pname, setPname] = useState(projectDetails?.name || "");
  const [description, setDescription] = useState(projectDetails?.description);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [usecaseType, setUsecaseType] = useState();
  const [summaryModal, setIsSummaryModalOpen] = useState(false);
  const [summaryData, setSummaryData] = useState({
    name: "",
    role: "",
    feedback: "",
    conversation: ""
  });

  const toggleNameEdit = () => setIsEditingName(!isEditingName);
  const toggleDescriptionEdit = () => setIsEditingDescription(!isEditingDescription);

  const handleNameChange = (e) => setPname(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const fetchProjectDetails = async () => {
    try {
      setIsLoading(true);
      console.log("fetch project called");
      const response_project = await axios.get(
        `${BASE_URL}/api/projects/${room_name}`
      );
      const fetchedProjectDetails = response_project.data;
      console.log("project details fetched", response_project.data);
      setProjectDetails(fetchedProjectDetails); // Store project details in state
      // Fetch shared projects if fetchedProjectDetails.id exists
      if (fetchedProjectDetails.id) {
        const sharedProjectsResponse = await axios.get(
          `${BASE_URL}/api/fetch_shared_projects/${fetchedProjectDetails.id}`
        );
        console.log("shared projects fetched", sharedProjectsResponse.data);
        setSharedProjects(sharedProjectsResponse.data);

        // Handle the shared projects data as needed
      }
      console.log("fetchedProjectDetails.regenerate_flag", fetchedProjectDetails.regenerate_flag)
      if (fetchedProjectDetails.regenerate_flag == true) {
        console.log("entered the true")
        handleGeneratePRD(
          fetchedProjectDetails.prd,
          fetchedProjectDetails.slide_content,
          fetchedProjectDetails.regenerate_flag,
          fetchedProjectDetails.id,
          fetchedProjectDetails.timer
        );
      }
    } catch (error) {
      console.error("Error fetching project details:", error);

      // Check if the error response status is 404
      if (error.response && error.response.status === 404) {
        // handleGeneratePRD(); // Call handleGeneratePRD if 404 error occurs
      } else {
        // Handle other errors if necessary
        console.error("An unexpected error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Function to generate PRD using the saved conversation data
  const handleGeneratePRD = (prd, slide_content,regenerate_flag, id, remainingTime) => {
    const requestData = {
      room_name: room_name,
      remaining_time: remainingTime,
    };

    console.log("this is request data", requestData);
    console.log("project details in generate prd", prd, regenerate_prd);
    if ((regenerate_flag == false) && prd) {
      setPrdContent(prd);
      console.log("PRD already exists.");
      if (!slide_content) {
        setisDeckGenerating(true);
        console.log("started deck generation");

        setisDeckGenerating(false);
      }
      return;
    } else {
      setisPrdGenerating(true);
      setisDeckGenerating(true);

      // First API call: Generate PRD
      axios
        .post(`${BASE_URL}/api/generate-prd/`, requestData)
        .then((response_prd) => {
          console.log("prd generated", response_prd);
          toast.success("PRD Generated Successfully..", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              backgroundColor: "#4caf50", // Background color
              color: "#fff", // Text color
              borderRadius: "8px", // Rounded corners
              padding: "10px", // Padding inside the toast
              fontSize: "16px", // Font size
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Box shadow
            },
          });

          // Assuming the PRD content is returned in the response data
          setPrdContent(response_prd.data.prd);
          setisPrdGenerating(false);

          // Now, make the second API call with the data from the first API call
          const slideApiUrl = `${BASE_URL}/api/slides/${id}`;
          return axios.post(slideApiUrl, {
            prd_content: response_prd.data.prd, // Send PRD content in the body
          });
        })
        .then((slideResponse) => {
          console.log("Slide response received", slideResponse);
          toast.success("Deck Generated Successfully..", {
            position: "top-right", // Position the toast
            autoClose: 5000, // Time for which the toast is visible
            hideProgressBar: false, // Show progress bar
            closeOnClick: true, // Close the toast when clicked
            pauseOnHover: true, // Pause toast on hover
            draggable: true, // Make the toast draggable
            progress: undefined,
            style: {
              backgroundColor: "#4caf50", // Background color
              color: "#fff", // Text color
              borderRadius: "8px", // Rounded corners
              padding: "10px", // Padding inside the toast
              fontSize: "16px", // Font size
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Box shadow
            },
          });
          setisDeckGenerating(false);
          const status = axios.post(`${BASE_URL}/api/projects/${id}`);
          console.log("regenerate flag updated", status);
        })
        .catch((error) => {
          console.error("Error generating PRD or fetching slide:", error);

          if (error.response) {
            // Handle the error response from the backend
            if (error.response.status === 400) {
              toast("Conversation data is insufficient for generating PRD.");
            } else {
              toast("An error occurred while generating PRD.");
            }
          } else {
            // If no response from the server, handle network-related errors
            toast("Network error. Please try again later.");
          }
        })
        .finally(() => {
          setisPrdGenerating(false);
          setisDeckGenerating(false);
        });
    }
  };

  // Handle PRD click
  const handlePrdClick = () => {
    setprdmodalopen(true);
  };
  const closeDeckModal = () => {
    setIsDeckModalOpen(false); // Close the modal
  };
  // Function to generate Deck (logs PRD content)
  const handleDeckClick = () => {
    setDeckProjId(projectDetails?.id);
    setUsecaseType("slidenarrator");
    setIsDeckModalOpen(true);

  };

  // Sequential execution of tasks
  useEffect(() => {
    const executeSequentially = async () => {
      // projid, useCaseType, uname, lroom_name, chat
      const proj_id = localStorage.getItem("sp");
      if (projid || proj_id){
        const token = localStorage.getItem("token"); // Get the token from localStorage

    if (proj_id) {
      const response = await fetch(`${BASE_URL}/api/decrypt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
        body: JSON.stringify({ encrypted_data: proj_id }),
      });

      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem("feedback_room", data.feedback_room);
        console.log("this is data", data.decrypted_data);
        setDeckProjId(data.decrypted_data);
        setUsecaseType("feedback");
        setIsDeckModalOpen(true);
      }
    }
    else{
        setDeckProjId(projid);
        setUsecaseType("feedback");
        setIsDeckModalOpen(true);
    }
      }
      console.log("project_id", project_id)
      console.log("from conv ", regenerate_prd, remainingTime, project_id, first);
      if (project_id) {
        setIsLoading(true);
        console.log("fetch project called", typeof project_id);
        const response_project = await axios.get(
          `${BASE_URL}/api/get-project/${project_id}`
        );
        const fetchedProjectDetails = response_project.data;
        console.log("project details fetched", response_project.data);
        setProjectDetails(fetchedProjectDetails); // Store project details in state

        // Fetch shared projects if fetchedProjectDetails.id exists
        if (fetchedProjectDetails.id) {
          const sharedProjectsResponse = await axios.get(
            `${BASE_URL}/api/fetch_shared_projects/${fetchedProjectDetails.id}`
          );
          console.log("shared projects fetched", sharedProjectsResponse.data);
          setSharedProjects(sharedProjectsResponse.data);
          // Handle the shared projects data as needed
        }
        console.log("regenerate flag", fetchedProjectDetails.regenerate_flag);
        if (fetchedProjectDetails.regenerate_flag == true) {
          console.log("entered the true using project id")
          handleGeneratePRD(
            fetchedProjectDetails.prd,
            fetchedProjectDetails.slide_content,
            fetchedProjectDetails.regenerate_flag,
            fetchedProjectDetails.id,
            fetchedProjectDetails.timer
          );
        }
        setIsLoading(false);
        if (fetchedProjectDetails.feedback == null) {
          setIsFeedbackOpen(true); // Open modal if `prd` is empty
        } else {
          setIsFeedbackOpen(false); // Ensure modal doesn't open unnecessarily
        }
      } else {
        await fetchProjectDetails();
      }
    };

    executeSequentially();
  }, []);

  const handleContinueChat = () => {
    navigate(`/brainstorm/`, {
      state: {
        remainingTime: effectiveRemainingTime,
        usecaseType: "default",
      },
    });
  };

  const fetchSharedUsers = async () => {
    if (!projectDetails?.id) return; // Ensure projectDetails is loaded
    try {
      const response = await axios.get(
        `${BASE_URL}/api/fetch_shared_users_expert/${projectDetails.id}/`
      );
      setSharedUsers(response.data); // Store shared users in state
      console.log("Shared users fetched successfully:", response.data);
    } catch (error) {
      console.error("Error fetching shared users:", error);
    }
  };

  const handleCardClick = async(projectId) => {
    setDeckProjId(projectId);
    console.log("project id shared proj", deckProjId);

    setTimeout(3000); 
    const token =  localStorage.getItem("token")
    setActiveSlideContent(projectId); // Set the content for the active slide
    console.log("selected project", projectId);
    const response = await fetch(`${BASE_URL}/api/project/get-room-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
      body: JSON.stringify({ 
        token: token,
        projectId: projectId
      }),
    });
    const data = await response.json();
    if (data.status === 200) {
      localStorage.setItem("feedback_room", data.room);
      setUsecaseType("feedback")
    setIsDeckModalOpen(true);
  }
  };
  // Pass this function to CollaborationSection via props
  useEffect(() => {
    if (projectDetails?.id) {
      fetchSharedUsers();
    }
  }, [projectDetails]);

  //Prd/Deck close on escape
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        closeDeckModal();
        setprdmodalopen(false);

        if (window.speechSynthesis) {
          window.speechSynthesis.cancel();
        }
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  // Update remainingTime logic
  const effectiveRemainingTime =
    remainingTime && !isNaN(remainingTime)
      ? remainingTime
      : projectDetails?.timer || 0; // Default to 0 if both are invalid

  const openModal = () => {
    setIsRequestMoreOpen(true);
  };

const closeModal = () => {
  setIsRequestMoreOpen(false);
};

const updateProjectDebounced = debounce(async () => {
  try {
    const response = await axios.put(`${BASE_URL}/api/projects/${projectDetails?.id}`, {
      name: pname,
      description: description,
    });
    console.log("Project updated:", response.data);
  } catch (error) {
    console.error("Error updating project:", error);
  }
}, 1000);

useEffect(() => {
  if (pname || description) {
    updateProjectDebounced();
  }
}, [pname, description]);

const handleSummaryClick = (name, role, feedback, conversation) =>{
  setSummaryData({name, role, feedback, conversation});
  setIsSummaryModalOpen(true);
};
  return (

<div className="bg-white min-h-screen overflow-hidden">
  <ToastContainer />
  {prdmodalopen && (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto py-10"
    >
      <div
        className="bg-white w-full max-w-5xl rounded-lg shadow-lg mx-4 relative overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
        style={{ maxHeight: "90vh" }}
      >
        <PRDPage toggleModal = {setprdmodalopen}/>
      </div>
    </div>
  )}
  <Header />
  <main className="xl:px-32 xl:py-8 lg:px-24 lg:py-6 md:px-16 md:py-4 px-4 py-2">
    {isLoading ? (
      <div className="flex justify-center items-center h-full">
        <img src={Loader} alt="Loading" className="w-16 h-16" />
      </div>
    ) : (
      <>
      <h1 className="text-3xl text-black font-robo font-semibold">
        My Workspace
      </h1>
        {!(projectDetails?.id || project_id) ? (
          <div className="flex flex-col text-xl mt-8 font-semibold rounded-none max-w-[370px] text-neutral-900" onClick={() => {
            handleContinueChat();
          }}>
          <div className="flex flex-col justify-center items-center px-12 py-11 w-full bg-white rounded-2xl border border-solid mb-52 border-zinc-100 shadow-[0px_2px_8px_rgba(182,191,200,0.3)] max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col w-56 max-w-full">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/03899d8705fa141089a8aa93c310261a12333b607b705967444e3ef38c98324c?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                alt="Project start icon"
                className="object-contain self-center w-10 rounded-none aspect-square cursor-pointer"
              />
              <div className="mt-9" tabIndex="0" role="heading" aria-level="1">
                Start your first project!
              </div>
            </div>
          </div>
        </div>
        ) : (
          <>
              <div className="pt-5 font-robo text-base pb-8 text-neutral-500">
            <p className="flex items-center space-x-2">
              {/* Editable Name */}
              <strong className="text-black flex items-center">
                {isEditingName ? (
                  <input
                    type="text"
                    value={pname}
                    onChange={handleNameChange}
                    onBlur={toggleNameEdit}
                    className="bg-transparent focus:outline-none text-black w-auto placeholder-gray-400"
                    autoFocus
                    placeholder="Click to add project name"
                  />
                ) : (
                  <span
                    className={`cursor-pointer hover:bg-gray-100 px-1 py-0.5 rounded-md transition-all ${
                      !pname ? "text-black" : ""
                    }`}
                    onClick={toggleNameEdit}
                  >
                    {(pname || projectDetails?.name) || "Click to add project name"}
                  </span>
                )}
                :
              </strong>

              {/* Editable Description */}
              <span className="flex-1">
                {isEditingDescription ? (
                  <textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    onBlur={toggleDescriptionEdit}
                    className="bg-transparent focus:outline-none text-black w-full resize-none overflow-hidden placeholder-gray-400"
                    rows="1"
                    autoFocus
                    placeholder="Click to add project description"
                  />
                ) : (
                  <span
                    className={`cursor-pointer hover:bg-gray-100 px-1 rounded-md transition-all ${
                      !description ? "text-gray-400" : ""
                    }`}
                    onClick={toggleDescriptionEdit}
                  >
                    {(description||projectDetails?.description) || "Click to add project description" }
                  </span>
                )}
              </span>
            </p>
        </div>
            <div className="flex flex-col text-base rounded-none mb-6 sm:mb-8">
              <div
                className={`flex flex-wrap gap-0 sm:gap-3 md:gap-3 lg:gap-5 xl:gap-5 justify-between items-center p-4 md:p-6 lg:p-6 xl:p-6 w-full bg-white rounded-2xl border border-solid border-zinc-100 ${(((projectDetails?.timer || effectiveRemainingTime) > 0) && isPrdGenerating) ? 'shadow-[0px_2px_8px_rgba(139,92,246,0.5)]' : 'shadow-[0px_2px_8px_rgba(182,191,200,0.3)]'} max-md:px-5 max-md:max-w-full`}
              >
                <div className="flex gap-1.5 sm:gap-2 md:gap-2 lg:gap-3.5 xl:gap-3.5 self-stretch text-2xl font-semibold whitespace-nowrap text-neutral-900">
                <div className="flex flex-col justify-center items-center px-1 w-6 sm:w-6 md:w-8 lg:w-8 xl:w-8 h-6 sm:h-6 md:h-8 lg:h-8 xl:h-8  rounded bg-slate-100">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/0d46026332ba1065b9de910774494197adf9fc290c58ccdfed93282d09c1a4dc?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                    alt=""
                    className="object-contain shrink-0 w-6 sm:w-6 md:w-8 lg:w-8 xl:w-8 h-6 sm:h-6 md:h-8 lg:h-8 xl:h-8 rounded-none aspect-square"
                  />
                  </div>
                  <div className="my-auto basis-auto text-base sm:text-lg md:text-xl lg:text-2xl xl:text-2xl">Conversations</div>
                </div>
                <div className={`flex gap-0 sm:gap-3 md:gap-3 lg:gap-5 xl:gap-5 self-stretch my-auto text-neutral-900 text-opacity-50`}>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/66920a971c2864ffb8a2b99bb757388fd7e6aee7228754dcf730c9ab94c93f15?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                    alt=""
                    className="object-contain shrink-0 w-6 aspect-square xl:mx-0 lg:mx-0 md:mx-0 sm:mx-0 mx-1"
                  />
                  {(() => {
                    const totalSeconds = effectiveRemainingTime; // Use effectiveRemainingTime directly

                        if (totalSeconds <= 0) {
                          return <div className="my-auto">Completed</div>; // Show "Completed" when time is 0 or less
                        }

                        const minutes = Math.floor(totalSeconds / 60);
                        const seconds = totalSeconds % 60;

                        return (
                          <div className="my-auto">
                            {totalSeconds < 60
                              ? `${totalSeconds} secs`
                              : `${minutes}:${
                                  seconds < 10 ? "0" : ""
                                }${seconds} mins`}
                          </div>
                        );
                      })()}
                    </div>
                    <button
                      className="flex gap-0 sm:gap-2 mx-8 sm:mx-0 self-stretch my-auto font-medium text-sky-600"
                      onClick={() => {
                        if (projectDetails?.timer > 0) {
                          handleContinueChat();
                        } else {
                          toast.error(
                            "No time left to continue the conversation.",
                            {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            }
                          );
                        }
                      }}
                    >
                      <div className="grow my-auto">Continue Conversation</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/f583f3ec862db5ac04ae5ad0d094481241bca2f13d4956ef3d80801ac99ee273?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                        alt=""
                        className="object-contain shrink-0 w-6 aspect-square"
                      />
                    </button>
                  </div>
                </div>
                {/* Documents Section */}
                <div
                  className={`w-full border border-zinc-100 bg-white bg-opacity-50 rounded-2xl ${
                    (projectDetails?.timer || remainingTime) <= 0 ||
                    !isPrdGenerating
                      ? "shadow-[0px_2px_8px_rgba(139,92,246,0.5)]"
                      : "shadow-[0px_2px_8px_rgba(182,191,200,0.3)]"
                  }`}
                >
                  <div className="flex justify-between items-center px-4 md:px-6 lg:px-6 xl:px-6 pt-6 ">
                    <div className="flex items-center gap-2">
                      <div className="flex flex-col justify-center items-center px-1 w-6 sm:w-6 md:w-8 lg:w-8 xl:w-8 h-6 sm:h-6 md:h-8 lg:h-8 xl:h-8  rounded bg-slate-100">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/18a91de92ca5d53f02aaaa576f132c1cf5bac719ec4093ef5e778e8a33da6537?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                          alt="Documents Icon"
                          className="object-contain shrink-0 w-6 sm:w-6 md:w-8 lg:w-8 xl:w-8 h-6 sm:h-6 md:h-8 lg:h-8 xl:h-8 aspect-square"
                        />
                      </div>
                      <div className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-2xl font-semibold mx-0 sm:mx-0.25 md:mx-0.5 lg:mx-2 xl:mx-2 text-neutral-900">
                        Documents
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">
                      Last updated{" "}
                      {(() => {
                        const updatedAt = new Date(
                          projectDetails?.updated_time
                        );
                        const now = new Date();
                        const diffInSeconds = Math.floor(
                          (now - updatedAt) / 1000
                        ); // Difference in seconds
                        const diffInMinutes = Math.floor(diffInSeconds / 60);
                        const diffInHours = Math.floor(diffInMinutes / 60);
                        const diffInDays = Math.floor(diffInHours / 24);
                        const diffInMonths = Math.floor(diffInDays / 30);

                        if (diffInMonths > 0) {
                          return `${diffInMonths} month${
                            diffInMonths !== 1 ? "s" : ""
                          } ago`;
                        } else if (diffInDays > 0) {
                          return `${diffInDays} day${
                            diffInDays !== 1 ? "s" : ""
                          } ago`;
                        } else if (diffInHours > 0) {
                          return `${diffInHours} hour${
                            diffInHours !== 1 ? "s" : ""
                          } ago`;
                        } else if (diffInMinutes > 0) {
                          return `${diffInMinutes} min${
                            diffInMinutes !== 1 ? "s" : ""
                          } ago`;
                        } else {
                          return `${diffInSeconds} sec${
                            diffInSeconds !== 1 ? "s" : ""
                          } ago`;
                        }
                      })()}
                    </div>
                  </div>

                  {/* Horizontal scrollable section on small devices */}
                  <div
                    className={`
    items-center
    justify-center
    flex 
    gap-1 
    overflow-x-auto 
    pt-4 
    px-7 
    py-4
    no-scrollbar 
    whitespace-nowrap 
    scroll-padding-left-7
    sm:flex 
    sm:gap-5 
    sm:overflow-x-auto 
    sm:py-4 
    sm:px-6 
    sm:ms-6
    sm:no-scrollbar 
    sm:whitespace-nowrap 
    md:flex 
    md:gap-5 
    md:overflow-x-auto 
    md:py-4 
    md:px-0 
    md:ms-3
    md:no-scrollbar 
    md:whitespace-nowrap 
    lg:flex 
    lg:gap-5 
    lg:overflow-x-auto 
    lg:py-6 
    lg:px-6 
    lg:ms-32
    lg:me-32
    lg:no-scrollbar 
    lg:whitespace-nowrap 
    xl:grid xl:grid-cols-3 xl:pt-7 xl:py-8 xl:ms-32 xl:me-32 xl:gap-10 
    gap-[20px]
  `}
                    style={{
                      gap:
                        window.innerWidth <= 1388 && window.innerWidth >= 1280
                          ? "10px"
                          : "",
                    }}
                  >
                    <div className="flex-shrink-0 sm:inline-block w-5/12 xs:w-3/6 sm:w-[40%] md:w-[30%] lg:w-[300px] ms-32 sm:ms-32 md:ms-0 md:me-3 sm:px-3 px-0">
                      <div
                        className={`
      w-full 
      sm:h-[160px] 
      h-[100px]
      flex 
      items-center 
      justify-center 
      border 
      border-gray-200 
      overflow-hidden 
      rounded 
      relative
      ${isPrdGenerating ? "cursor-not-allowed" : "cursor-pointer"}
    `}
                        onClick={() => !isPrdGenerating && handlePrdClick()}
                      >
                        <img
                          src={Prdimage}
                          alt="PRD"
                          className="w-full h-full object-cover"
                          style={{ filter: "blur(1px)" }}
                        />
                        {isPrdGenerating ? (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <img
                              src={first == "true" ? Loader : Updating}
                              alt={
                                first == "true"
                                  ? "Generating PRD"
                                  : "Updating PRD"
                              }
                              className={`w-${
                                first == "true" ? "20" : "10"
                              } h-${first == "true" ? "15" : "10"} sm:w-${
                                first == "true" ? "40" : "10"
                              } sm:h-${first == "true" ? "30" : "10"}`}
                            />
                          </div>
                        ) : (
                          regenerate_prd && ( // Show "Newtag" only if regenerate_prd is true
                            <div className="flex items-center justify-center">
                              <img
                                src={Newtag} // Replace Newtag with the path to your image
                                alt="Newtag"
                                className="absolute top-0 right-0 w-10 h-10"
                              />
                            </div>
                          )
                        )}
                      </div>
                      <div className="mt-2 text-sm text-center text-neutral-900">
                        PRD
                      </div>
                    </div>

                    <div className="flex-shrink-0 sm:inline-block w-5/12 sm:w-[40%] md:w-[30%] lg:w-[300px] sm:px-3 px-0">
                      <div
                        className={`
      w-full 
      sm:h-[160px] 
      h-[100px]
      flex 
      items-center 
      justify-center 
      border 
      border-gray-200 
      overflow-hidden 
      rounded 
      relative
      ${isDeckGenerating ? "cursor-not-allowed" : "cursor-pointer"}
    `}
                        onClick={() => !isDeckGenerating && handleDeckClick()}
                      >
                        <img
                          src={Slideimage}
                          alt="Deck"
                          className="w-full h-full object-cover"
                          style={{ filter: "blur(1px)" }}
                        />
                        {isDeckGenerating ? (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <img
                              src={first == "true" ? Loader : Updating}
                              alt={
                                first == "true"
                                  ? "Generating Deck"
                                  : "Updating Deck"
                              }
                              className={`w-${
                                first == "true" ? "20" : "10"
                              } h-${first == "true" ? "15" : "10"} sm:w-${
                                first == "true" ? "40" : "10"
                              } sm:h-${first == "true" ? "30" : "10"}`}
                            />
                          </div>
                        ) : (
                          regenerate_prd && ( // Show "Newtag" only if regenerate_prd is true
                            <div className="flex items-center justify-center">
                              <img
                                src={Newtag} // Replace Newtag with the path to your image
                                alt="Newtag"
                                className="absolute top-0 right-0 w-10 h-10"
                              />
                            </div>
                          )
                        )}
                      </div>
                      <div className="mt-2 text-sm text-center text-neutral-900">
                        Deck
                      </div>
                    </div>

                    <div className="flex-shrink-0 sm:inline-block w-5/12 sm:w-[40%] md:w-[30%] lg:w-[300px] sm:px-3 px-0">
                      <div
                        className="
              w-full 
              sm:h-[160px] 
              h-[100px]
              flex 
              items-center 
              justify-center 
              border 
              border-gray-200 
              rounded
              cursor-pointer
            "
                        onClick={openModal}
                      >
                        <div className="w-16 h-16 rounded-lg bg-gray-100 flex items-center justify-center ">
                          <div className="text-6xl font-medium">+</div>
                        </div>
                      </div>
                      <div className="mt-2 text-sm text-center text-black">
                        Request More
                      </div>
                    </div>
                  </div>
                </div>
                {sharedUsers.length > 0 ? (
                  <div className="mt-8">
                    <h2 className="text-sm font-medium sm:text-base sm:font-normal md:text-base md:font-normal lg:text-base lg:font-normal xl:text-base xl:font-normal mb-2 text-black">
                      Request Status
                    </h2>

                    {/* Display table for larger screens */}
                    <div className="hidden sm:block">
                      <div className="rounded-lg border border-gray-300 overflow-hidden">
                        <table className="table-auto border-collapse w-full text-center text-sm">
                          <thead className="text-black bg-violet-100">
                            <tr>
                              <th className="px-4 py-2">Name</th>
                              <th className="px-4 py-2 border-l border-gray-300">
                                Time
                              </th>
                              <th className="px-4 py-2 border-l border-gray-300">
                                Role
                              </th>
                              <th className="px-4 py-2 border-l border-gray-300">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sharedUsers.map((user, index) => (
                              <tr
                                key={index}
                                className={`hover:bg-gray-100 text-black ${
                                  index === sharedUsers.length - 1
                                    ? "last:rounded-bl-lg last:rounded-br-lg"
                                    : ""
                                }`}
                              >
                                <td className="px-4 py-2 flex justify-between items-center">
                                  <div className="flex items-center">
                                    <img
                                      src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/b8b6abad549413e72104e38126323dd03c7a77cb5a3052edf755d909cc892ab9?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                                      alt="User Icon"
                                      className="w-6 h-6 mr-4 bg-slate-400 rounded-full"
                                    />
                                    {user.name}
                                  </div>
                                  <div 
                                    role="button" 
                                    tabIndex={0}
                                    className={`flex gap-1 justify-center items-center pt-1 pb-0.5 text-xs text-center rounded cursor-pointer ${user.status !== 'verified' ? 'bg-gray-100 text-gray-500 cursor-not-allowed' : 'text-sky-600 bg-indigo-50'}`}
  onClick={user.status === 'verified' ? () => handleSummaryClick(user.name, user.role, user.feedback, user.conversation) : undefined} 
                                    style={{ pointerEvents: user.status !== 'verified' ? 'none' : 'auto' }} 
                                  >
                                    <img
                                      loading="lazy"
                                      src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/52cd77d7bd31143229d7a1578ab8b011b4aad76e5cc75e328bafea1205a0776d?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                                      alt=""
                                      className="object-contain shrink-0 self-stretch my-auto w-3 aspect-square"
                                    />
                                    <div className="self-stretch my-auto">View Summary</div>
                                  </div>
                                </td>
                                <td className="px-4 py-2 border-l bordergray-300">
                                  {user.time}
                                </td>
                                <td className="px-4 py-2 border-l bordergray-300">
                                  {user.role}
                                </td>

                                <td className="px-4 py-2 border-l border-gray-300">
                                  {user.status === "unregistered" ? (
                                    <img
                                      loading="lazy"
                                      src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/77e6aa53c057248f214a6e4715a1a3608a724a256828db8056ddac291ab80733?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                                      alt="Unregistered"
                                      className="w-6 h-6 mx-auto"
                                    />
                                  ) : user.status === "pending" ? (
                                    <div className="w-6 h-6 mx-auto border border-gray-300 rounded"></div>
                                  ) : user.status === "verified" ? (
                                    <img
                                      loading="lazy"
                                      src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/1389290b0297ec5d74a2d602855d5cd3ce37e795ea4da679896f076fdf232ddd?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                                      alt="Verified"
                                      className="w-6 h-6 mx-auto bg-green-500 rounded-md"
                                    />
                                  ) : (
                                    <span>N/A</span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* Display dropdown for smaller screens */}
                    <div className="block sm:hidden">
                      {sharedUsers.map((user, index) => (
                        <details
                          key={index}
                          className="mb-4 border border-gray-100 rounded"
                        >
                          <summary className="flex items-center justify-between gap-4 px-4 py-2 bg-white cursor-pointer">
                            <div className="flex items-center  text-gray-700 gap-4">
                              <img
                                src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/b8b6abad549413e72104e38126323dd03c7a77cb5a3052edf755d909cc892ab9?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                                alt="User Icon"
                                className="w-6 h-6 bg-slate-400 rounded-full"
                              />
                              {user.name}
                            </div>
                            <span className="text-4xl transition-transform duration-200">
                              <RiArrowDropDownLine />
                              {/* Default icon */}
                            </span>
                          </summary>

                          <div className="flex flex-col gap-2 text-black text-sm px-4 py-2">
                            <div className="flex items-center">
                              <span className="font-semibold mr-1">Type:</span>
                              <span>{user.role}</span>
                            </div>

                            <div className="flex items-center">
                              <span className="font-semibold mr-1">Time:</span>
                              <span>{user.time}</span>
                            </div>

                            <div className="flex items-center">
                              <span className="font-semibold mr-1">
                                Status:
                              </span>
                              {user.status === "unregistered" ? (
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/77e6aa53c057248f214a6e4715a1a3608a724a256828db8056ddac291ab80733?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                                  className="w-4 h-4"
                                  alt="Unregistered"
                                />
                              ) : user.status === "pending" ? (
                                <div className="w-4 h-4 border border-gray-300 rounded"></div>
                              ) : user.status === "verified" ? (
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/1389290b0297ec5d74a2d602855d5cd3ce37e795ea4da679896f076fdf232ddd?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                                  className="w-4 h-4"
                                  alt="Verified"
                                />
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                        </details>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p className="text-gray-500"></p>
                )}

                <CollaborationSection
                  projectId={projectDetails?.id}
                  onCloseAction={fetchSharedUsers}
                  hasSharedUsers={sharedUsers.length > 0}
                />
              </>
            )}

            <div>
              <p className="text-black text-3xl text-black font-robo font-bold mt-8 mb-5">
                Projects shared with you
              </p>
            </div>
            {sharedProjects?.length > 0 ? (
              <div>
                <div className="grid gap-6 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
                  {sharedProjects.map((project) => (
                    <SharedProjectCard
                      key={project.id}
                      projectName={project.name}
                      description={project.desc}
                      onClick={() => handleCardClick(project.id)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div>You don’t have any shared projects at the moment.</div>
            )}
          </>
        )}
      </main>
      <Modal
        isOpen={isDeckModalOpen}
        onRequestClose={() => {
          closeDeckModal();
          if (window.speechSynthesis) {
            window.speechSynthesis.cancel();
          }
        }}
        contentLabel="Deck Modal"
        
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20"
      >
        <button
          className="absolute top-2 right-2 text-white bg-red-600 p-2 rounded-full z-50"
          onClick={() => {
            closeDeckModal();
            if (window.speechSynthesis) {
              window.speechSynthesis.cancel();
            }
          }}
        >
          X
        </button>
        <div className="w-full h-full transform translate-y-5">
        <LiveKit projectId = {deckProjId} usecaseType = {usecaseType} setIsDeckModalOpen={setIsDeckModalOpen} />
        </div>
      </Modal>

      <FeedbackModal
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        projectId={projectDetails?.id}
      />

      <Modal
        isOpen={isRequestMoreOpen}
        onRequestClose={closeModal}
        contentLabel="Request More"
        className="w-[80%] max-w-md mx-auto p-4 bg-white rounded-lg shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      >
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-500"
        >
          X
      </button>
      <RequestMore onRequestClose={closeModal} projectId = {projectDetails?.id || project_id}
      onRequestStatus={(isSuccess) => {
        if (isSuccess) {
            // Handle success case (e.g., show a success modal)
            setShowSuccessModal(true)
            console.log("Request was successful!");
        } else {
            // Handle failure case (e.g., show an error message)
            console.error("Request failed.");
        }
    }}/> {/* Render your bRequestMore component here */}
  </Modal>
  
  {summaryModal && (
    <Modal
    isOpen = {summaryModal}
    onRequestClose={() => setIsSummaryModalOpen(false)}
    contentLabel="Success"
    className="mx-auto my-24 w-[70%] justify-center items-center rounded-2xl bg-white"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50 "
    >
     <SummaryModal 
     name={summaryData.name} 
     role={summaryData.role} 
     feedback={summaryData.feedback} 
     conversation={summaryData.conversation} 
     onClose={() => setIsSummaryModalOpen(false)} />
    </Modal>)}
  {/* Success Modal */}
  {showSuccessModal && (
    <Modal
  isOpen={showSuccessModal}
  onRequestClose={() => setShowSuccessModal(false)}
  contentLabel="Success"
  className="relative w-full max-w-3xl mx-auto p-6 rounded-2xl z-50"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
>
  <div className="relative w-full h-auto bg-white rounded-2xl shadow-lg p-6">
    {/* Close Icon */}
    <button
      className="absolute top-4 right-4 text-gray-800 hover:text-gray-600"
      aria-label="Close"
      onClick={() => setShowSuccessModal(false)}
    >
      <svg
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5 1.5L1.5 13.5M1.5 1.5L13.5 13.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </button>

    {/* Modal Content */}
    <div className="flex flex-col items-center justify-center h-auto">
      {/* Success Icon */}
      <img
        src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/37915bbd5eb489794ef46b88f0e9fe90f7b41ce46f42f710b1734ceae23e800a?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
        alt="Success confirmation icon"
        className="w-12 h-12 mb-4 mt-2"
      />
      
      {/* Title */}
      <h2 className="xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl text-sm mt-4 font-semibold text-black text-center mb-4">
        Request Submitted Successfully
      </h2>

      {/* Description */}
      <p className="xl:text-2xl lg:text-2xl md:text-xl sm:text-sm text-xs font-normal text-gray-600 text-center px-4 mb-4">
        Thank you for your request! Our team will review your document request and get back to you.
      </p>
    </div>
  </div>
</Modal>
      )}
    </div>
  );
};

export default RqPage;
