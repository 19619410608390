"use client";

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Slide from "../components/slides/Slide";
import SlideControls from "../components/slides/SlideControls";
import axios from "axios";
import config from "../url_config/urls_config.js";
import { Loader2 } from "lucide-react";
import { useChat } from "@livekit/components-react";

const BASE_URL = config.getRq_api();

const Deck = ({ project_id }) => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFullscreen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { send } = useChat();
  const [text, setText] = useState("");

  useEffect(() => {
    const fetchSlides = async () => {
      const roomName = localStorage.getItem("room_name");
      console.log("this is project id", project_id);

      if (project_id) {
        try {
          const response = await axios.get(
            `${BASE_URL}/api/slides/${project_id}`
          );
          console.log(response);
          
          setSlides(response.data.slides);
          console.log("Fetching slides for Project ", project_id);
          setIsLoading(false)
        } catch (error) {
          console.error("Error fetching slides for project:", error);
        }
      } else if (roomName) {
        try {
          setIsLoading(true);
          const response = await axios.get(
            `${BASE_URL}/api/slides/${roomName}/room_name`
          );
          setSlides(response.data.slides);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching slides:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error("Room name not found in local storage");
      }
    };

    fetchSlides();
  }, [project_id]);


  useEffect(() => {
    console.log(currentSlide + 1);
  }, [currentSlide]);

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1 && isFullscreen) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
      // setText(`can u explain what is this `);
      handleSendMessage();
    }
  };

  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
      handleSendMessage();
    }
  };

  const restartPresentation = () => {
    if (isFullscreen) {
      setCurrentSlide(0);
    }
  };

  const startPresentation = () => {
    if (isFullscreen) {
      setCurrentSlide(0);
    }
  };

  useEffect(() => {
    if (slides[currentSlide + 1]) {
      const slide = slides[currentSlide + 1];
      const { layout, title, content, voiceCommentary } = slide;

      // Ensure content is an array before mapping
      const contentDetails = Array.isArray(content)
        ? content
            .map(
              (item, index) =>
                `${index + 1}. ${item.title} - ${item.description}`
            )
            .join("\n")
        : "No additional details available.";

      // Combine all details into the text prompt
      const prompt = `
    Slide Title: ${title || "Untitled"}
    Layout: ${layout || "Unknown"}
    Details:
    ${contentDetails}
    
    Voice Commentary:
    ${voiceCommentary || "No commentary available."}
  `;

      setText(
        `#0000# Can you tell me more about this slide if u dont have much information just explain the voice commentary?\n${prompt}`
      );
    } else {
      setText("#0000# Loading slide content...");
    }
  }, [currentSlide, slides]);

  const handleSendMessage = () => {
    send(text); // Send the formatted text prompt
  };

  return (
    <div className="h-full flex flex-col ">
      {/* Modal content container */}
      <div className="flex flex-col flex-grow overflow-hidden">
        {isLoading && (
          <div className="fixed inset-0 bg-gradient-to-br from-gray-900 to-gray-800 bg-opacity-75 flex items-center justify-center z-50 backdrop-blur-md">
            <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-2xl flex flex-col items-center max-w-sm w-full mx-4">
              <Loader2 className="h-16 w-16 animate-spin text-blue-500 dark:text-blue-400" />
              <p className="text-xl font-semibold text-gray-800 dark:text-gray-200 mt-6 mb-4 animate-pulse">
                Generating slides...
              </p>
            </div>
          </div>
        )}

        {!isLoading && slides.length > 0 ? (
          <>
            {/* Slide container */}
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
              className="flex-grow mb-4 overflow-auto rounded-lg shadow-lg"
            >
              {currentSlide !== null && <Slide data={slides[currentSlide]} />}
            </motion.div>

            {/* Controls */}
            <div className="mt-auto">
              <SlideControls
                currentSlide={currentSlide}
                totalSlides={slides.length}
                onNext={handleNextSlide}
                onPrev={handlePrevSlide}
                isFullscreen={isFullscreen}
                onStartPresentation={startPresentation}
                onRestartPresentation={restartPresentation}
              />
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-xl text-gray-600">No slides available.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Deck;
