import React from "react";

const RequestMoreCard = ({ text, imagePath, isChecked, onCheckboxChange }) => {
  return (
    <div className="relative w-[160px] h-[144px]">
      {/* Checkbox wrapped in a label for click handling */}
      <label htmlFor={`customCheckbox-${text}`} className="cursor-pointer">
        {/* SVG with Drop Shadow */}
        <div
        className={`absolute top-[30px] left-[0.5px] w-[158px] h-[113px] rounded-lg ${
          isChecked
            ? "bg-gradient-to-b from-[#EAE7F4] to-[#D5C9FF] shadow-lg"
            : "bg-white border-2 border-[#DAD0FA] shadow-sm"
        }`}
      ></div>

        {/* Expanded Text Box */}
        <div className="absolute top-[75px] left-[50%] transform -translate-x-1/2 text-center text-black font-semibold text-sm w-[90px] cursor-pointer">
          {text.split("\n").map((line, index) => (
            <div key={index}>{line}</div> // Render each line in a block
          ))}
        </div>
      </label>

      {/* Circle with Image */}
      <div
        className={`absolute top-[-0px] left-[50%] transform -translate-x-1/2 w-[59px] h-[59px] rounded-full bg-white ${
          isChecked ? "border-2" : "border-[2.25px]"
        } border-[#DAD0FA] flex items-center justify-center`}
      >
        <img
          src={imagePath}
          alt="Custom"
          className="w-[30px] h-[30px] object-cover z-10"
        />
      </div>

      {/* Checkbox in the Bottom Right */}
      <div className="absolute bottom-2 right-2">
        <input
          type="checkbox"
          id={`customCheckbox-${text}`}
          className="hidden"
          checked={isChecked}
          onChange={onCheckboxChange}
        />
        <label
          htmlFor={`customCheckbox-${text}`}
          className={`w-[18px] h-[18px] rounded-md border-2 border-[#5a64ff] cursor-pointer flex items-center justify-center ${
            isChecked ? "bg-[#5a64ff]" : ""
          }`}
        >
          {isChecked && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="white"
              viewBox="0 0 16 16"
              className="mt-1"
            >
              <path
                fillRule="evenodd"
                d="M13.485 1.343a1 1 0 0 1 0 1.414L5.5 10.5 2.515 7.515a1 1 0 1 1 1.414-1.414L5.5 8.586l7.071-7.071a1 1 0 0 1 1.414 0z"
              />
            </svg>
          )}
        </label>
      </div>
    </div>
  );
};

export default RequestMoreCard;
