import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdLogOut } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa"; // User profile icon

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const userProfileRef = useRef(null);

  const handleLogout = () => {
    return new Promise((resolve) => {
      // Clear token from local storage
      localStorage.removeItem("token");
      resolve();
      // Use a small timeout to ensure removal is complete
      setTimeout(() => {
        // Verify token is removed before navigating
        if (!localStorage.getItem("token")) {
          resolve();
          window.location.reload(); // Refresh the current page
        }
      }, 200);
    });
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !userProfileRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="flex justify-between items-center xl:px-20 lg:px-12 md:px-12 sm:px-4 px-4 py-3.5 bg-blue-50 text-3xl font-bold max-sm:px-3 ">
      {/* Left aligned RQ */}
      <Link to="/dashboard" className="font-monst cursor-pointer bg-gradient-to-r from-indigo-950 via-violet-800 to-blue-600 text-transparent bg-clip-text text-black xl:ml-12 lg:ml-12 md:ml-4">
        RQ
      </Link>

      {/* Right aligned User Profile and Logout */}
      <div className="relative xl:mr-12 lg:mr-12 md:mr-4" ref={userProfileRef}>
        <div
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="cursor-pointer"
        >
          <FaUserCircle size={40} className="text-black" />
        </div>

        {dropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute top-full right-5 mt-2 w-32 p-2 bg-white shadow-lg rounded-md z-10"
          >
            <button
              onClick={handleLogout}
              className="flex items-center gap-4 text-black w-full justify-start hover:bg-gray-100 p-2 rounded"
            >
              <IoMdLogOut size={24} />
              <span className="text-sm">Logout</span>
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
