import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios
import RequestMoreCard from "./RequestMoreCard";
import MarketResearch from "../../assets/MarketResearch.png";
import SoftArch from "../../assets/SoftArch.png";
import TechReq from "../../assets/TechReq.png";
import UserStories from "../../assets/UserStories.png";
import ProductRoad from "../../assets/ProductRoad.png";
import Wire from "../../assets/Wire.png";
import CodeRest from "../../assets/CodeRest.png";
import GTMStrat from "../../assets/GTMStrat.png";
import config from "../../url_config/urls_config";

const BASE_URL = config.getRq_api();
const RequestMore = ({ onRequestClose, onRequestStatus, projectId }) => {
    const [checkedItems, setCheckedItems] = useState([]); // To store checked items
    const [errorMessage, setErrorMessage] = useState(""); // State for error message


    const cardsData = [
        { text: "Market Research", img: MarketResearch },
        { text: "Software Architecture", img: SoftArch },
        { text: "Technical Requirements", img: TechReq },
        { text: "User\nStories", img: UserStories },
        { text: "Product Roadmaps", img: ProductRoad },
        { text: "Wireframes and Mockups", img: Wire },
        { text: "Code Structure", img: CodeRest },
        { text: "GTM Strategy", img: GTMStrat },
    ];

    // Handle checkbox change
    const handleCheckboxChange = (text) => {
        setCheckedItems((prevCheckedItems) =>
            prevCheckedItems.includes(text)
                ? prevCheckedItems.filter((item) => item !== text) // Uncheck item
                : [...prevCheckedItems, text] // Check item
        );
    };

    // Function to handle the request button click
    const handleRequest = async () => {
        if (checkedItems.length === 0) {
            setErrorMessage("Please select at least one document."); // Set error message
            return; // Prevent further execution
        }

        try {
            const response = await axios.post(`${BASE_URL}/api/project/requested_documents`, {
                projectId: projectId,
                checkedItems: checkedItems,
            });
            console.log("Response:", response.data);
            // Optionally, you can close the modal or show a success message here
            if (response.data === 200) {
              onRequestStatus(true);
            }
            onRequestClose();
        } catch (error) {
            console.error("Error sending request:", error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <>
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-10 flex items-center justify-center">
                <div className="relative w-[804px] h-[645px] bg-white rounded-2xl shadow-lg p-6 z-70">
                    {/* Close Icon */}
                    <button
                        className="absolute top-4 right-4 text-black hover:text-gray-600"
                        aria-label="Close"
                        onClick={onRequestClose}
                    >
                        <svg
                            width={24}
                            height={25}
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19 6.47261L17.59 5.04883L12 10.6935L6.41 5.04883L5 6.47261L10.59 12.1173L5 17.7619L6.41 19.1857L12 13.541L17.59 19.1857L19 17.7619L13.41 12.1173L19 6.47261Z"
                                fill="black"
                            />
                        </svg>
                    </button>

                    {/* Main Content */}
                    <div className="flex flex-col items-center justify-center h-full">
                        {/* Adjusted "Coming Soon" heading */}
                        <h1 className="text-black text-4xl font-semibold mb-12 ">Coming Soon</h1>
                        {errorMessage && <p className="text-red-500">{errorMessage}</p>} {/* Display error message */}
                        <div className="grid grid-cols-4 gap-6 p-6">
                            {cardsData.map((card, index) => (
                                <RequestMoreCard
                                    key={index}
                                    text={card.text}
                                    imagePath={card.img}
                                    isChecked={checkedItems.includes(card.text)}
                                    onCheckboxChange={() => handleCheckboxChange(card.text)}
                                />
                            ))}
                        </div>
                        <button className="flex items-center justify-center w-4/12 h-12 mt-9 text-white bg-blue-600 hover:bg-white hover:text-blue-600 hover:border-2 hover:border-blue-600 rounded" onClick={handleRequest}>
                            Request
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
  
};

export default RequestMore;