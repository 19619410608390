import * as React from "react";

export default function SummaryModal({ name, role, feedback, conversation, onClose }) {
  const [isConversationVisible, setIsConversationVisible] = React.useState(false);

  const handleToggleConversation = () => {
    setIsConversationVisible((prevState) => !prevState);
  };

  return (
<div className="flex flex-col rounded-none w-full h-1/4 relative">
  {/* Close Button */}
  <button
    onClick={onClose}
    className="absolute top-8 right-8 text-gray-500 hover:text-gray-700 focus:outline-none"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>

  <div className="flex flex-col px-8 pt-8 pb-16 w-full rounded-2xl max-md:px-5 max-md:max-w-2xl bg-white">
    {/* Header Section */}
    <div className={`flex gap-5 max-md:flex-col`}>
      {/* Feedback Section */}
      <div
        className={`flex flex-col ${
          isConversationVisible ? "md:w-[39%]" : "md:w-full"
        } ${isConversationVisible && "max-md:hidden"}`}
      >
        <div className="flex flex-col mt-9 w-full text-base max-md:mt-10">
          <div className="flex gap-4">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/94c8015d1d215b923afbe03e4d98bbd4936a9e5a51d5f9ab04a82174e0b4d33c?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
              alt={`${name}'s profile picture`}
              className="object-contain shrink-0 self-start w-12 aspect-square"
            />
            <div className="flex flex-col grow shrink-0 items-start basis-0 w-fit">
              <div className="text-4xl ml-2 font-semibold text-center text-neutral-900">
                {name}
              </div>
              <div className="text-center mt-1 ml-2 text-xl text-black">
                {role}
              </div>
              <div
                className={`self-stretch text-justify text-neutral-500 ${
                  isConversationVisible
                    ? "text-base overflow-y-scroll mt-9"
                    : "text-2xl ml-3 mr-20 mt-14"
                }`}
                style={{ maxHeight: "45vh" }}
              >
                {feedback}
              </div>
            </div>
          </div>
          <button
            className={`gap-2.5 self-start pt-2.5 pb-1.5 mt-12  ${
              isConversationVisible ? "ml-16" : "ml-20"
            } font-medium text-sky-600 border-b border-sky-600 max-md:ml-2.5`}
            onClick={handleToggleConversation}
          >
            {isConversationVisible ? "Hide Conversation" : "View Conversation"}
          </button>
        </div>
      </div>

      {/* Conversation Section */}
      {isConversationVisible && (
        <div
          className={`flex ${
            isConversationVisible ? "md:w-[60%]" : "w-full"
          } max-md:block`}
        >
          {/* Partition line for larger screens */}
          <div className="hidden md:block w-[1px] bg-gray-300 h-full mx-4"></div>

          {/* Conversation section */}
          <div
            className={`flex flex-col items-center w-full overflow-y-scroll rounded p-4 ${
              isConversationVisible ? "block" : "hidden"
            } max-md:block`}
            style={{ maxHeight: "70vh" }}
          >
            {conversation.map((msg, index) => (
              <div
                key={index}
                className={`flex ${
                  msg.role === "user" ? "justify-end" : "justify-start"
                } gap-2 mt-4 w-full`}
              >
                {/* Avatar for non-user messages */}
                {msg.role !== "user" && (
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/4eb34357850af0b05f5aee455bdef5c5884db4d12f3548321b0328913e26eb92?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                    alt="User avatar"
                    className="object-contain shrink-0 aspect-square rounded-full w-[22px]"
                  />
                )}

                {/* Message bubble */}
                <div
                  className={`px-3 py-2 text-sm ${
                    msg.role === "user"
                      ? "bg-violet-100 text-neutral-900"
                      : "bg-gray-100 text-neutral-900"
                  } rounded-lg max-w-[80%] break-words`}
                >
                  {msg.content}
                </div>

                {/* Icon for user messages */}
                {msg.role === "user" && (
                  <div className="flex items-center justify-center w-[22px] h-[22px] bg-violet-100 rounded-full border border-solid border-white border-opacity-30">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/400217ecaddec2bb376cfe1bc6cd74c8ada78d2eddc3e47e523b0dbd3e8680ad?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                      alt="User icon"
                      className="object-contain aspect-square w-[11px]"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  </div>
</div>


  );
}
