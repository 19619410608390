import * as React from "react";

export const ExpertCard = ({ imageSrc, name, role, desc, selected }) => {
  return (
    <div
      className={`flex flex-col mt-6 sm:mt-7 group items-center justify-center w-full xl:w-[180px] xl:h-[140px] xl:mx-4 lg:w-[180px] lg:h-[140px] md:w-[180px] md:h-[140px] sm:w-[180px] sm:h-[140px] lg:mx-4 mx-auto w-[110px] h-[110px] rounded-lg border border-solid border-zinc-100 ${
        selected
          ? "shadow-[0px_2px_8px_rgba(139,92,246,0.5)]"
          : "shadow-[0px_2px_8px_rgba(182,191,200,0.3)] hover:shadow-[0px_2px_8px_rgba(139,92,246,0.5)]"
      } transition-all duration-300 xl:p-4 lg:p-4 md:p-4 sm:p-4 p-2`}
    >
      <img
        loading="lazy"
        src={imageSrc}
        alt={`Profile picture of ${name}`}
        className="object-contain sm:w-12 sm:h-12 xl:w-16 xl:h-16 lg:w-16 lg:h-16 md:w-16 md:h-16 h-8 w-8 group-hover:hidden"
      />
      <div className="text-sm xl:text-lg lg:text-lg md:text-lg sm:text-lg font-semibold text-neutral-900 text-center group-hover:hidden">
        {name}
      </div>
      <div className="text-xs xl:text-base lg:text-base md:text-base sm:text-base font-normal text-neutral-500 text-center group-hover:hidden">
        {role}
      </div>

      {/* Hover content */}
      <div className="hidden group-hover:flex flex-col items-center">
        <div className="text-sm xl:text-lg lg:text-lg md:text-lg sm:text-lg font-semibold text-neutral-900">
          {name}
        </div>
        <div className="text-xs xl:text-base lg:text-base md:text-base sm:text-base font-normal text-neutral-500">
          {role}
        </div>
        <p className="mt-2 text-xs sm:text-sm text-neutral-500 text-center">
          {desc}
        </p>
      </div>
    </div>
  );
};
