
import React, { useState } from "react";
import config from "../../url_config/urls_config";

const BASE_URL = config.getRq_api();
const FeedbackModal = ({ isOpen, onClose, projectId }) => {
  const [rating, setRating] = useState(4);
  const [feedback, setFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // To handle the loading state

  // Function to handle star clicks
  const handleRating = (index) => {
    setRating(index + 1);
  };

  // Function to submit feedback to the backend
  const submitFeedback = async () => {
    setIsSubmitting(true);
    const data = [rating, feedback]; // Send rating and feedback as a list

    try {
      const response = await fetch(`${BASE_URL}/api/feedback/${projectId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            rating: rating,
            feedback: feedback,
          }),
      });

      if (response.ok) {
        console.log("Feedback submitted successfully!");
        onClose(); // Close the modal after submission
      } else {
        console.error("Error submitting feedback");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Network error. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-xl p-6 relative py-12 px-12">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 "
          >
            &#x2715;
          </button>

          {/* Title */}
          <h2 className="text-center text-2xl font-bold mb-2">
            Your PRD will be generated in <span className="text-black">02:00mins</span>
          </h2>

          {/* Subtitle */}
          <p className="text-center text-gray-500 mb-4">
            Meanwhile Share your experience to help us improve!
          </p>

          {/* Star Rating */}
          <div className="flex justify-center mb-4">
            {[...Array(5)].map((_, index) => (
              <button
                key={index}
                onClick={() => handleRating(index)}
                className={`text-4xl ${index<rating ? "text-yellow-300" : "text-gray-300"} focus:outline-none`}
              >
                {index < rating ? "★" : "☆"}
              </button>
            ))}
          </div>

          {/* Feedback Input */}
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Share more details about your experience (optional)"
            className="w-full border bg-white rounded-lg p-2 mb-4 focus:ring focus:ring-blue-300"
            rows="3"
          ></textarea>

          {/* Submit Button */}
          <button
            onClick={submitFeedback}
            disabled={isSubmitting}
            className={`w-full font-semibold py-2 rounded-lg transition ${
              isSubmitting
                ? "bg-blue-300 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 text-white"
            }`}
          >
            {isSubmitting ? "Submitting..." : "Submit Feedback"}
          </button>
        </div>
      </div>
    )
  );
};

export default FeedbackModal;
