import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import RqPage from "./pages/RqPage";
import Deck from "./pages/Deck";
import PRDPage from "./pages/PRDPage";
import SignupPage from "./pages/SignupPage";
import LoginPage from "./pages/LoginPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import ForbiddenPage from "./pages/ForbiddenPage";
import NotFoundPage from "./pages/NotFoundPage";
import LiveKit from "./pages/Livekit.tsx";
import ResetPassword from "./components/Authentication/ResetPassword.jsx"; // Import the ResetPassword component
import RegisterPage from "./pages/Register";
import { ProjectProvider } from "./context/ProjectContext";
import ProtectedRoutes from "./utils/ProtectedRoute.jsx";
import InterstitialPage from "./pages/InterstitialPage.jsx";

// Component to conditionally render headers based on the route
const AppHeader = () => {
  const location = useLocation();
  const isRqPage = location.pathname.startsWith("/dashboard");
  const isPrdPage = location.pathname.startsWith("/prd");
  const isUserStory = location.pathname.startsWith("/user-stories");
  const isChatWindow = location.pathname.startsWith("/brainstorm/");
  return !isRqPage && !isChatWindow && !isPrdPage && !isUserStory ? (
    <div></div>
  ) : null;
};

// Component to conditionally render Footer based on the route
const AppFooter = () => {
  const location = useLocation();
  const isRqPage = location.pathname.startsWith("/dashboard");
  const isPrdPage = location.pathname.startsWith("/prd");
  const isUserStory = location.pathname.startsWith("/user-stories");
  const isChatWindow = location.pathname.startsWith("/brainstorm/");
  return !isRqPage && !isChatWindow && !isPrdPage && !isUserStory ? (
    <div></div>
  ) : null;
};

function App() {
  const [darkMode, setDarkMode] = useState(false);

  // Check if a user is logged in by verifying the presence of a token in localStorage
  const isUserLoggedIn = () => {
    const token = localStorage.getItem("token");
    return token ? true : false;
  };

  return (
    <ProjectProvider>
      <Router>
        <div className="App flex">
          <div className="flex-grow">
            <AppHeader />
            <Routes>
              {/* Redirect to /dashboard if user is already logged in */}
              <Route
                path="/"
                element={
                  isUserLoggedIn() ? (
                    <Navigate to="/dashboard" replace />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route path="/signup" element={<SignupPage />} />
              <Route element={<ProtectedRoutes />}>
                <Route path="/dashboard" element={<RqPage />} />
                <Route path="/prd/" element={<PRDPage darkMode={darkMode} />} />
                <Route
                  path="/brainstorm/" // Change project_id to projectId
                  element={
                    <LiveKit
                      darkMode={false}
                      inputValue=""
                      onInputChange={() => {}}
                      templateId="1"
                    />
                  }
                />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/interstitial" element={<InterstitialPage />} />
                <Route path="/deck" element={<Deck />} />
              </Route>
              <Route path="/unauthorized" element={<UnauthorizedPage />} />
              <Route path="/forbidden" element={<ForbiddenPage />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
            <AppFooter />
          </div>
        </div>
      </Router>
    </ProjectProvider>
  );
}

export default App;
