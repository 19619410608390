import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../url_config/urls_config";
import LoadingSpinner from "../components/Common/Loadingspinner"

const BASE_URL = config.getRq_api();

const useAuthCheck = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      try {
        // Get token from localStorage
        const token = localStorage.getItem("token");

        // If no token is found, set authentication to false
        if (!token) {
          setIsAuthenticated(false);
          navigate("/");
          return;
        }

        // Send the request with the token in the Authorization header
        const response = await fetch(`${BASE_URL}/auth/validate-user/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Attach the JWT token
          },
        });

        if (response.status === 401) {
          // If the response is 401, redirect to Unauthorized page
          setIsAuthenticated(false);
          localStorage.clear()
          navigate("/");
        } else if (response.ok) {
          // If the response is valid, set authentication to true
          setIsAuthenticated(true);
        } else {
          console.error("Unexpected response status:", response.status);
          localStorage.clear()
          setIsAuthenticated(false);
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        localStorage.clear()
        setIsAuthenticated(false);
        navigate("/");
      }
    };

    checkToken();
  }, [navigate]); // Dependency array includes navigate to avoid stale closures

  return isAuthenticated;
};

const ProtectedRoutes = () => {
  const isAuthenticated = useAuthCheck();

  // If the authentication check is still in progress, show nothing or a loading indicator
  if (isAuthenticated === null) {
    return <LoadingSpinner/>;
  }

  // If the user is authenticated, render protected routes, otherwise redirect
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
