import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loginframe from "../assets/loginframe.jpg";
import config from "../url_config/urls_config";
import rightmarksignup from "../assets/rightmarksignup.png";
import GoogleLoginButton from "../components/button/Google_button/GoogleLoginButton";
import smloginframe from "../assets/smloginframe.jpg";
const Base_url = config.getRq_api();

const SignUpPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [fieldError, setFieldError] = useState({});
  const [error, setError] = useState(null);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlUsername = params.get("username");
    const urlToken = params.get("token");
    const urlName = params.get("name");
    const urlRoomName = params.get("room_name");

    if (urlUsername && urlToken && urlName) {
      // Store the values in localStorage
      localStorage.setItem("username", urlUsername);
      localStorage.setItem("token", urlToken);
      localStorage.setItem("name", urlName);
      localStorage.setItem("room_name", urlRoomName);

      // Redirect to dashboard if coming from Google login
      navigate("/register");
    }
  }, [navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const email = e.target.email.value;

    setFieldError({});
    setIsLoading(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setIsLoading(false);
      setFieldError((prev) => ({
        ...prev,
        email: "Please enter a valid email address (e.g., example@domain.com).",
      }));
      return;
    }

    const generateRandomRoomName = (name, email) => {
      const sanitized_name = name.trim().replace(/[^a-zA-Z0-9]/g, "");
      const sanitized_email = email
        .split("@")[0]
        .trim()
        .replace(/[^a-zA-Z0-9]/g, "");
      const randomNumber = Math.floor(Math.random() * 10000);
      return `rq_${sanitized_name}_${sanitized_email}_${randomNumber}`;
    };

    const room_name = generateRandomRoomName(name, email);

    try {
      const response = await fetch(`${Base_url}/auth/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, name, room_name }),
      });

      const data = await response.json();

      if (response.ok) {
        setError(null);
        navigate("/", {
          state: { emailEntered: true, email: email },
        });
      } else {
        if (data.detail && data.detail.includes("Email already registered")) {
          setFieldError((prev) => ({
            ...prev,
            email: "Email already registered.",
          }));
        } else {
          setError(data.error || "Unknown error");
        }
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setError("An unexpected error occurred");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let timer;
    if (isSignedUp && countdown > 0) {
      timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
    } else if (countdown === 0) {
      navigate("/", {
        state: { emailEntered: true, email: email },
      });
    }

    return () => clearInterval(timer);
  }, [isSignedUp, countdown, navigate]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-[#99B5D4] to-[#DDCFE8] p-4">
      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg w-full max-w-4xl overflow-hidden">
        {/* Image section - moved to top on mobile, side on desktop */}
        <div className="w-full md:w-1/2 order-last md:order-last">
          {/* Image for md and above */}
          <img
            src={loginframe}
            alt="signup"
            className="w-full h-full object-cover hidden md:block md:-mb-0"
          />

          {/* Image for below md */}
          <img
            src={smloginframe}
            alt="signup"
            className="w-full h-full object-cover rotate-90 -mb-24 p-10 md:hidden"
          />
        </div>

        {/* Form section */}
        <div className="w-full md:w-1/2 flex flex-col justify-center px-6 md:px-12 py-8">
          {isSignedUp ? (
            <div className="flex flex-col items-center justify-center align-middle text-center">
              <div className="flex  items-center mb-6">
                <img
                  src={rightmarksignup}
                  alt="rightmarkframe"
                  className="h-32 w-auto object-contain mb-4"
                />
                <div className="ml-10">
                  <h1 className="text-2xl font-bold text-blue-500 font-monst text-left">
                    Thank You For Signing Up!
                  </h1>
                  <p className="text-gray-600 text-sm mt-2 font-robo text-left">
                    We've sent an OTP to your email. Use it to log in and get
                    started!
                  </p>
                </div>
              </div>

              <div className="mt-6 w-full max-w-md p-4 border rounded-lg bg-gray-100">
                <p className="text-sm font-semibold text-red-500">
                  You will be redirected to the home page in{" "}
                  <span className="text-3xl font-extrabold text-blue-700">
                    {countdown}
                  </span>{" "}
                  seconds...
                </p>
                <span className="block mt-3 text-gray-600">
                  <strong>Or, you can</strong>{" "}
                  <button
                    onClick={() =>
                      navigate("/", {
                        state: { emailEntered: true, email: email },
                      })
                    }
                    className="text-blue-600 font-semibold underline cursor-pointer hover:text-blue-800"
                  >
                    click here to navigate
                  </button>
                </span>
              </div>
            </div>
          ) : (
            <>
              <h1 className="text-3xl text-blue-700 mb-4 font-extrabold font-monst">
                Sign Up
              </h1>
              <p className="text-gray-500 mb-6 text-xs font-semibold font-robo">
                Welcome to RQ, Let's get you started
              </p>

              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="border-b-2 focus:outline-none focus:border-black mb-4 pb-2 w-full bg-white text-black "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={30} /* Restrict to 12 characters */
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="border-b-2 focus:outline-none focus:border-black mb-4 pb-2 w-full bg-white text-black"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    e.target.setCustomValidity(""); // Reset custom validity on change
                  }}
                  onInvalid={(e) => {
                    e.target.setCustomValidity("Invalid Email Address."); // Custom error message
                  }}
                  required
                />

                {fieldError.email && (
                  <p className="text-red-500 text-sm">{fieldError.email}</p>
                )}
                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

                <button
                  type="submit"
                  className={`border-solid border-2 border-sky-500 w-full px-6 py-2 rounded font-extrabold font-robo text-LoginBlue ${
                    isLoading
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-blue-500 hover:text-white"
                  }`}
                  disabled={isLoading}
                >
                  {isLoading ? "Signing Up..." : "Sign Up"}
                </button>
              </form>

              <div className="my-4 flex items-center">
                <hr className="flex-grow border-t border-gray-400" />
                <span className="mx-2 text-gray-500">or</span>
                <hr className="flex-grow border-t border-gray-400" />
              </div>

              <div className="flex justify-center w-full">
                <GoogleLoginButton
                  buttonText="Continue with Google"
                  className="!text-gray-400" // Using "!important" utility to override internal styles if needed
                />
              </div>

              <p className="text-gray-500 mt-4 text-sm text-left">
                Already have an account?
                <strong
                  className="text-blue-600 underline font-extrabold ml-2 cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  Log In
                </strong>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
