import React from 'react';

interface ExtendTimePopupProps {
  onExtend: () => void;
  onCancel: () => void;
}

const ExtendTimePopup: React.FC<ExtendTimePopupProps> = ({ onExtend, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#ffffff] p-10 rounded-xl shadow-lg " style={{ height: '350px', width: '700px' }}>
        <h2 className="text-3xl font-bold text-center mt-10">Need More Time?</h2>
        <p className='text-center text-2xl mt-5'>Would you like to extend your conversation for an additional 2 minutes?</p>
        <div className="flex justify-center">
          <button
            onClick={onCancel}
            className="border border-red-500 text-[#C70003] mx-4 mt-10 px-8 py-2 rounded text-red"
          >
            No, End Conversation
          </button>
          <button
            onClick={onExtend}
            className="border bg-[#0060C7] text-white mx-4 mt-10 px-8 py-2 rounded"
          >
            Yes, Extend Time
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExtendTimePopup;
