import React from "react";

function SharedProjectCard({ projectName, description, onClick }) {
  return (
    <div
      className="flex flex-col text-xs rounded-lg w-full h-full"
      onClick={onClick} // Call the onClick function passed as a prop
    >
      <div className="flex flex-col items-start px-4 md:px-12 lg:px-16 xl:px-16 sm:px-8 py-4 w-full h-full rounded-lg border border-solid bg-white bg-opacity-50 border-zinc-100 shadow-[0px_1px_6px_rgba(182,191,200,0.3)]">
        <div className="text-xs sm:text-base font-medium text-neutral-900">{projectName}</div>
        <div className="self-stretch text-[10px] sm:text-sm mt-3.5 text-neutral-500">
          {description}
        </div>
        <button
          className="gap-2 self-stretch px-4 md:px-4 lg:px-4 xl:px-4 sm:px-4 py-2 mt-4 font-bold text-[9px] sm:text-sm text-blue-600 bg-white rounded border border-blue-600 border-solid min-h-[26px] hover:bg-blue-600 hover:text-white hover:border-white"
          tabIndex={0}
          aria-label={`Add Your Thoughts about ${projectName}`}
          onClick={(e) => {
            e.stopPropagation(); // Prevent the click from propagating to the parent div
            onClick(); // Call the onClick handler
          }}
        >
          Add Your Thoughts
        </button>
      </div>
    </div>
  );
}

export default SharedProjectCard;