import React, { useState ,useRef} from 'react';
import { Trash2, AlertCircle } from 'lucide-react';
import {ConfirmationMessage} from "./ConfirmationMessage"; 
import config from "../../url_config/urls_config";

const BASE_URL = config.getRq_api();

const CollaboratorRow = ({
  onNameChange,
  onEmailChange,
  name,
  email,
  disabled,
  onDelete,
  onEnter,
}) => {
  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);

  const handleKeyDown = (e, inputType) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inputType === "name") {
        // Focus on the email input of the same row
        if (emailInputRef.current) {
          emailInputRef.current.focus();
        }
      } else if (inputType === "email") {
        // Enable and focus the name input of the next row
        onEnter(() => {
          if (nameInputRef.current) {
            nameInputRef.current.focus();
          }
        });
      }
    }
  };

  return (
    <div className="relative flex gap-4 mt-4 w-full max-w-5xl">
      {/* Name Input */}
      <div className="flex-1">
        <input
          ref={nameInputRef}
          type="text"
          placeholder="Name"
          value={name}
          onChange={onNameChange}
          disabled={disabled}
          onKeyDown={(e) => handleKeyDown(e, "name")}
          className={`w-full px-4 py-3 bg-white rounded-lg border ${
            disabled ? "bg-gray-100 text-gray-500" : "border-gray-300"
          } focus:outline-none focus:border-sky-500`}
        />
      </div>

      {/* Email Input */}
      <div className="flex-1">
        <input
          ref={emailInputRef}
          type="email"
          placeholder="Email"
          value={email}
          onChange={onEmailChange}
          disabled={disabled}
          onKeyDown={(e) => handleKeyDown(e, "email")}
          className={`w-full px-4 py-3 bg-white rounded-lg border ${
            disabled ? "bg-gray-100 text-gray-500" : "border-gray-300"
          } focus:outline-none focus:border-sky-500`}
        />
      </div>

      {/* Delete Button */}
      {name && email && !disabled && (
        <button
          type="button"
          onClick={onDelete}
          className="absolute ps-6 right-[-2.5rem] ml-8 top-1/2 transform -translate-y-1/2 p-2 text-red-500 hover:bg-red-50 rounded"
          style={{ width: "40px", height: "40px" }}
        >
          <Trash2 size={20} />
        </button>
      )}
    </div>
  );
};


export const InviteCollaborator = ({ onClose, projectId, onAction  }) => {
  const [collaborators, setCollaborators] = useState([
    { name: '', email: '', isDisabled: false },
    { name: '', email: '', isDisabled: true }, // Second row is initially disabled
  ]);
  const [modalType, setModalType] = React.useState(null);
  const [warning, setWarning] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const refs = collaborators.map(() => ({
    nameRef: React.createRef(),
    emailRef: React.createRef(),
  }));

  const handleNameChange = (index) => (e) => {
    const newCollaborators = [...collaborators];
    newCollaborators[index].name = e.target.value;
    setCollaborators(newCollaborators);
  };

  const handleEmailChange = (index) => (e) => {
    const newCollaborators = [...collaborators];
    newCollaborators[index].email = e.target.value;
    // Enable the next row if it exists and is currently disabled
    if (
      index < collaborators.length - 1 &&
      newCollaborators[index + 1].isDisabled
    ) {
      newCollaborators[index + 1].isDisabled = false;
    }

    setCollaborators(newCollaborators);
  };

  const handleAddRow = () => {
    const lastRow = collaborators[collaborators.length - 1];
    if (!lastRow.name || !lastRow.email) {
      setWarning("Please fill out the previous collaborator's name and email before adding another.");
      return;
    }

    setWarning('');
    setCollaborators([
      ...collaborators,
      { name: '', email: '', isDisabled: true },
    ]);
  };

  const handleEnterKey = (index) => {
    const newCollaborators = [...collaborators];
    if (index < collaborators.length - 1) {
      newCollaborators[index + 1].isDisabled = false; // Enable the next row
      setCollaborators(newCollaborators);

      // Focus the next row's name input
    if (refs[index + 1] && refs[index + 1].nameRef.current) {
      refs[index + 1].nameRef.current.focus();
    }
    }
  
  };
  

  const handleDeleteRow = (indexToDelete) => {
          if (collaborators.length > 1) {
            const newCollaborators = collaborators.filter((_, index) => index !== indexToDelete);
            setCollaborators(newCollaborators);
          }
        };

  const handleAction = (type) => {
    setModalType(type);
    onAction(type); 
  };

  const closeModal = () => {
    setModalType(null);
    onClose(); 
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validCollaborators = collaborators.filter((c) => c.name.trim() && c.email.trim());
    
    console.log('Collaborators:', validCollaborators);
    console.log('Project id:', projectId);
    
    if (validCollaborators.length === 0) {
      setWarning("Please fill out at least one collaborator's details before submitting.");
      return;
  }

  // Validate email format for each filled collaborator
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  for (let collaborator of validCollaborators) {
      if (!emailRegex.test(collaborator.email)) {
          setWarning("Please enter a valid email address.");
          return;
      }
  }
  setWarning(""); 
  
    try {
      const response = await fetch(`${BASE_URL}/api/add_collaborators`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          project_id: projectId,
          collaborators: validCollaborators,
        }),
      });
      const result = await response.json();
      if (result.status === 200) {
        setIsConfirmationOpen(true);
      }
      console.log('Server Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
  {!isConfirmationOpen ? (
    <div className="flex justify-center items-center min-h-screen p-4 sm:p-6 bg-opacity-50">
      <div className="w-full max-w-lg md:max-w-3xl bg-white rounded-2xl shadow-lg px-6 py-8 sm:px-12 sm:py-16 lg:px-32 lg:py-28 relative">
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <button
            onClick={onClose}
            type="button"
            className="absolute top-3 right-3 sm:top-4 sm:right-4 text-gray-500 hover:text-gray-700 text-lg sm:text-xl"
          >
            ✕
          </button>

          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/135eb22d4c9ca74abcc0cbc31fb0d724ded8eb5026e539ddb12a0d5a8924c5fa?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
            className="object-contain mt-2 w-10 h-10 sm:w-12 sm:h-12 lg:w-14 lg:h-14"
            alt="Collaborator Icon"
          />

          <h1 className="mt-4 text-lg sm:text-xl font-semibold text-neutral-900 text-center">
            Invite a Collaborator
          </h1>

          <p className="mt-2 text-sm text-center text-neutral-500">
            Add a friend or colleague to get their opinion
          </p>

          <div className="w-full mt-6 space-y-4">
            {collaborators.map((collaborator, index) => (
              <CollaboratorRow
                key={index}
                name={collaborator.name}
                email={collaborator.email}
                disabled={collaborator.isDisabled}
                onNameChange={handleNameChange(index)}
                onEmailChange={handleEmailChange(index)}
                onDelete={() => handleDeleteRow(index)}
                onEnter={() => handleEnterKey(index)}
              />
            ))}
          </div>

          {warning && (
            <div className="flex items-center text-red-500 mt-2 text-sm w-full">
              <AlertCircle className="mr-2" size={20} />
              {warning}
            </div>
          )}

          <button
            type="button"
            onClick={handleAddRow}
            className="flex items-center gap-2 mt-6 text-sm sm:text-base font-medium text-sky-600"
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/fe0687f336f4c93a6692f438f32c4ee91827ab459a19b96be0bbf9d6dfb309a7?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
              className="object-contain w-5 h-5 sm:w-6 sm:h-6"
              alt="Add Icon"
            />
            <span>Add Another</span>
          </button>

          <button
            type="submit"
            className="w-full px-8 py-3 mt-8 text-sm sm:text-base font-bold text-white bg-sky-600 rounded"
          >
            Invite
          </button>
        </form>
      </div>
    </div>
  ) : (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <ConfirmationMessage
        projectId= {projectId}
        onClose={closeModal}
        onAction={handleAction}
        type ="Chat"
      />
    </div>
  )}
</>
  );
};