"use client";
import React from "react";
import { LoadingSVG } from "../button/LoadingSVG.tsx";
import { ChatMessageType } from "../chat/ChatTile.tsx";
import { AgentMultibandAudioVisualizer } from "../visualization/AgentMultibandAudioVisualizer.tsx";
import { useConfig } from "../../hooks/useConfig.tsx";
import { useMultibandTrackVolume } from "../../hooks/useTrackVolume.tsx";
import { TranscriptionTile } from "../../transcriptions/TranscriptionTile.tsx";
import { useKrispNoiseFilter } from "@livekit/components-react/krisp";
import {
  TrackReferenceOrPlaceholder,
  useConnectionState,
  useDataChannel,
  useLocalParticipant,
  useRemoteParticipants,
  useRoomInfo,
  useTracks,
} from "@livekit/components-react";
import {
  ConnectionState,
  LocalParticipant,
  RoomEvent,
  Track,
} from "livekit-client";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import "@livekit/components-styles";
import MicAndSpeaker from "../IntertesialPage/Mic_and_speaker.jsx";
import Deck from "../../pages/Deck.jsx";
import { FeedbackPlaygroundHeader } from "./FeedbackHeader.tsx";

export interface PlaygroundMeta {
  name: string;
  value: string;
}

export interface PlaygroundProps {
  collaboratorModeActive?: string;
  logo?: ReactNode;
  themeColors: string[];
  onConnect: (connect: boolean, opts?: { token: string; url: string }) => void;
  chat?: any;
  onCloseRoom?: (remainingTime: number | null) => void;
  remainingTime: number;
  project_id: number;
}

const headerHeight = 56;
export default function FeedbackPlayground({
  collaboratorModeActive,
  project_id,
  logo,
  themeColors,
  onConnect,
  chat,
  onCloseRoom,
  remainingTime,
}: PlaygroundProps) {
  const { config, setUserSettings } = useConfig();
  const [transcripts, setTranscripts] = useState<ChatMessageType[]>([]);
  const { localParticipant } = useLocalParticipant();
  const [showChat, setShowChat] = useState(true); // State to toggle chat visibility
  const [currentMessages, setCurrentMessages] = useState<ChatMessageType[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const krisp = useKrispNoiseFilter();
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    krisp.setNoiseFilterEnabled(true);
  }, []);
  useEffect(() => {
    const initializeMedia = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        console.log("Media permissions granted");
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    initializeMedia();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoad(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const participants = useRemoteParticipants({
    updateOnlyOn: [RoomEvent.ParticipantMetadataChanged],
  });
  const agentParticipant = participants.find((p) => p.isAgent);
  const isAgentConnected = agentParticipant !== undefined;

  const roomState = useConnectionState();
  const tracks = useTracks();

  useEffect(() => {
    if (roomState === ConnectionState.Connected) {
      localParticipant.setMicrophoneEnabled(config.settings.inputs.mic);
    }
  }, [config, localParticipant, roomState]);

  let agentAudioTrack: TrackReferenceOrPlaceholder | undefined;
  const aat = tracks.find(
    (trackRef) =>
      trackRef.publication.kind === Track.Kind.Audio &&
      trackRef.participant.isAgent
  );
  if (aat) {
    agentAudioTrack = aat;
  } else if (agentParticipant) {
    agentAudioTrack = {
      participant: agentParticipant,
      source: Track.Source.Microphone,
    };
  }

  const subscribedVolumes = useMultibandTrackVolume(
    agentAudioTrack?.publication?.track,
    5
  );

  const localTracks = tracks.filter(
    ({ participant }) => participant instanceof LocalParticipant
  );
  const localVideoTrack = localTracks.find(
    ({ source }) => source === Track.Source.Camera
  );
  const localMicTrack = localTracks.find(
    ({ source }) => source === Track.Source.Microphone
  );

  const localMultibandVolume = useMultibandTrackVolume(
    localMicTrack?.publication.track,
    20
  );

  const onDataReceived = useCallback(
    (msg: any) => {
      if (msg.topic === "transcription") {
        const decoded = JSON.parse(
          new TextDecoder("utf-8").decode(msg.payload)
        );
        let timestamp = new Date().getTime();
        if ("timestamp" in decoded && decoded.timestamp > 0) {
          timestamp = decoded.timestamp;
        }
        setTranscripts([
          ...transcripts,
          {
            name: "You",
            message: decoded.text,
            timestamp: timestamp,
            isSelf: true,
          },
        ]);
      }
    },
    [transcripts]
  );

  useDataChannel(onDataReceived);

  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    setIsMobile(width <= 768);
    setIsTablet(width > 768 && width <= 1024);
  };

  useEffect(() => {
    handleResize(); // Check on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const audioTileContent = useMemo(() => {
    const disconnectedContent = (
      <div className="flex flex-col items-center justify-center gap-2 text-gray-700 text-center w-full h-screen">
        No audio track. Connect to get started.
      </div>
    );

    const waitingContent = (
      <div className="flex flex-col items-center gap-2 text-gray-700 text-center w-full">
        <LoadingSVG />
        Waiting for audio track
      </div>
    );

    const visualizerContent = (
      <div>
        <AgentMultibandAudioVisualizer
          state="speaking"
          barWidth={isMobile ? 10 : 25} // Reduced bar width
          minBarHeight={isMobile ? 15 : 30} // Reduced minimum height
          maxBarHeight={isMobile ? 30 : 100} // Reduced maximum height
          accentColor={"#0060C7"}
          accentShade={500}
          frequencies={subscribedVolumes}
          borderRadius={isMobile ? 10 : 15} // Reduced border radius
          gap={isMobile ? 5 : 10} // Reduced gap between bars
        />
      </div>

      // <div>playing</div>
    );

    if (roomState === ConnectionState.Disconnected) {
      return disconnectedContent;
    }

    if (!agentAudioTrack) {
      return waitingContent;
    }

    return visualizerContent;
  }, [agentAudioTrack, subscribedVolumes, roomState, showChat, isMobile]);

  const chatTileContent = useMemo(() => {
    if (agentAudioTrack) {
      return (
        <TranscriptionTile
          agentAudioTrack={agentAudioTrack}
          accentColor={"cyan"}
          onMessagesUpdate={setCurrentMessages}
          chat={{ conversation: Array.isArray(chat) ? chat : [] }}
        />
      );
    }
    return <></>;
  }, ["cyan", agentAudioTrack, chat]);

  const autoConnect = () => {
    onConnect(roomState === ConnectionState.Disconnected);
  };

  const [micLevel, setMicLevel] = useState(0); // State for microphone level
  const [speakerVolume, setSpeakerVolume] = useState(0); // State for speaker volume

  const handleMicToggle = async (isOn: boolean) => {
    try {
      if (isOn) {
        if (roomState === ConnectionState.Connected) {
          await localParticipant.setMicrophoneEnabled(true);
          setMicLevel(Math.min(micLevel + 1, 20)); // Limit mic level to 20
          console.log("Microphone enabled");
        } else {
          console.error("Cannot enable microphone: not connected to the room.");
        }
      } else {
        await localParticipant.setMicrophoneEnabled(false);
        setMicLevel(0); // Reset mic level when disabled
        console.log("Microphone disabled");
      }
    } catch (error) {
      console.error("Error toggling LiveKit microphone:", error);
    }
  };

  const onVolumeChange = (newVolume: number) => {
    setSpeakerVolume(Math.min(newVolume, 40)); // Limit speaker volume to 50
  };

  // Add new state for mobile controls visibility
  const [showMobileControls, setShowMobileControls] = useState(true); // Initially show mobile controls

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMobileControls(false); // Hide after 10 seconds
    }, 5000); // 10 seconds

    return () => clearTimeout(timer);
  }, []); // Run only on mount

  // Toggle handler for mobile controls
  const handleVisualizerClick = () => {
    if (isMobile) {
      setShowMobileControls(!showMobileControls); // Toggle visibility on click
    }
  };

  return (
    <div className="h-screen flex p-10 relative ">
      {/* Main Content Area */}
      <div className="absolute top-0 left-0 w-full z-[100] mr-10">
        <FeedbackPlaygroundHeader
          title={config.title}
          logo={logo}
          githubLink={config.github_link}
          height={headerHeight}
          accentColor={"cyan"}
          connectionState={roomState}
          chat={chat}
          type = {collaboratorModeActive}
          onConnectClicked={() =>
            onConnect(roomState === ConnectionState.Disconnected)
          }
          messages={currentMessages}
          isAgentConnected={isAgentConnected}
          autoConnect={autoConnect}
          onCloseRoom={onCloseRoom}
          remainingTime={remainingTime}
          isMobile={isMobile}
        />
      </div>

      <div
        className={`flex-grow transition-all duration-300  ${
          isChatOpen ? "w-2/3" : "w-full"
        }`}
      >
        <Deck project_id={project_id} />
      </div>

      {/* Sidebar for Chat */}
      {isChatOpen && (
        <div className="w-1/3 h-full bg-white shadow-lg rounded-tl-lg transition-all duration-300 flex flex-col z-40 ml-10">
          {/* Close Button */}
          <div className="flex justify-end p-2">
            <button
              onClick={() => setIsChatOpen(false)}
              className="text-gray-500 hover:text-gray-800 mt-10 cursor-pointer size-4"
            >
              ✕
            </button>
          </div>

          {/* Chat Content */}
          <div className="flex-grow overflow-y-auto p-4 relative ">
            {chatTileContent}

            {/* Audio Tile Content and MicAndSpeaker */}
            <div
              className={`absolute top-0 left-0 w-full flex flex-col items-center z-50 mt-4 ${
                isInitialLoad ? "opacity-30" : "opacity-100"
              } p-2`}
            >
              <div className="flex items-center justify-center w-36 h-14 ">
                {audioTileContent}
              </div>

              <div className="mt-4 flex items-center justify-center shadow-md p-2 rounded-lg bg-white">
                <MicAndSpeaker
                  getResponsiveSize={(s, m1, m2, l) => s}
                  externalAudio={false}
                  onMicToggle={handleMicToggle}
                  volume={speakerVolume}
                  micLevel={micLevel}
                  isMobile={isMobile}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Floating Audio Tile */}
      {!isChatOpen && (
        <div
          className={`absolute z-20 bottom-20 right-20 transition-all duration-1000 ease-in-out flex flex-col items-center cursor-pointer ${
            isInitialLoad ? "opacity-30" : "opacity-100"
          } p-2`}
          onClick={() => setIsChatOpen(true)}
        >
          {/* Audio Tile Content */}
          <div className="flex items-center justify-center w-36 h-14 ">
            {audioTileContent}
          </div>

          {/* Mic and Speaker */}
          <div className="mt-4 flex items-center justify-center shadow-md p-2 rounded-lg bg-white">
            <MicAndSpeaker
              getResponsiveSize={(s, m1, m2, l) => s}
              externalAudio={false}
              onMicToggle={handleMicToggle}
              volume={speakerVolume}
              micLevel={micLevel}
              isMobile={isMobile}
            />
          </div>
        </div>
      )}
    </div>
  );
}
