import React, { useEffect, useRef, useState } from "react";
import { ChatMessage } from "./ChatMessage.tsx";
import { ChatMessageInput } from "./ChatMessageInput.tsx";
import { ChatMessage as ComponentsChatMessage } from "@livekit/components-react";
import DocumentUpload from "./DocumentUpload"; // Import the new component

const inputHeight = 48;

export type ChatMessageType = {
  name: string;
  message: string;
  isSelf: boolean;
  timestamp: number;
};

type ChatTileProps = {
  messages: ChatMessageType[];
  accentColor: string;
  onSend?: (message: string) => Promise<ComponentsChatMessage>;
};

export const ChatTile = ({ messages, accentColor, onSend }: ChatTileProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const prevMessagesLength = useRef(messages.length);
  const isUserScrolling = useRef(false);

  useEffect(() => {
    if (containerRef.current && !isUserScrolling.current) {
      const container = containerRef.current;
      const isNearBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight <
        100;
      if (shouldAutoScroll && messages.length > prevMessagesLength.current) {
        setTimeout(() => {
          if (container) {
            container.scrollTop = container.scrollHeight;
          }
        }, 0);
      }

      prevMessagesLength.current = messages.length;
    }
  }, [messages, shouldAutoScroll]);

  const handleScroll = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const isNearBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight <
        100;
      isUserScrolling.current = true;
      setShouldAutoScroll(isNearBottom);
      setTimeout(() => {
        isUserScrolling.current = false;
      }, 150);
    }
  };
  const groupedMessages = messages.reduce(
    (acc: ChatMessageType[][], curr, index) => {
      if (index === 0 || messages[index - 1].isSelf !== curr.isSelf) {
        acc.push([curr]);
      } else {
        const lastGroup = acc[acc.length - 1];
        const combinedMessage = {
          ...curr,
          message: lastGroup[0].message + " " + curr.message,
        };
        acc[acc.length - 1] = [combinedMessage];
      }
      return acc;
    },
    []
  );
  return (
    <div className="flex flex-col gap-6 w-full h-full text-white text-3xl font-sans py-3">
      <div
        ref={containerRef}
        className="overflow-y-auto"
        style={{
          height: `calc(100% - ${inputHeight}px)`,
        }}
        onScroll={handleScroll}
      >
        <div className="flex flex-col min-h-full justify-end p-3 py-px">
          {groupedMessages.map((group, groupIndex) => {
            const message = group[0];
            let skipNext = false; // Track whether the next message should be skipped

            // Check if the current message should be skipped
            if (skipNext) {
              skipNext = false; // Reset the flag for the next iteration
              return null;
            }

            // Skip rendering if the current message contains "#0000#"
            if (message.message.includes("#0000#")) {
              skipNext = true; // Set the flag to skip the next message
              return null;
            }

            // Check if the current message contains "#2345"
            if (message.message.includes("#2345")) {
              return (
                <div
                  key={groupIndex}
                  className="flex justify-end text-base my-2"
                >
                  <div className="w-1/2">
                    <DocumentUpload
                      documentName="Document Uploaded"
                      documentType="PDF"
                    />
                  </div>
                </div>
              );
            }

            return (
              <ChatMessage
                key={groupIndex}
                hideName={false}
                name={message.name}
                message={message.message}
                isSelf={message.isSelf}
                accentColor="#6B7280"
                isLastInGroup={true}
              />
            );
          })}
        </div>
      </div>
      <ChatMessageInput
        height={inputHeight}
        placeholder="Type a message"
        accentColor="#6B7280"
        onSend={onSend}
      />
    </div>
  );
};
