import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Switch } from "@mui/material";
import MicOffIcon from "@mui/icons-material/MicOff";
import VolumeUpIcon from "../../assets/IoVolumeHighOutline.svg";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const MicAndSpeaker = ({ getResponsiveSize, externalAudio, onMicToggle, micLevel, volume, isMobile }) => {
  const [isMicOn, setIsMicOn] = useState(false);
  const [isSpeakerOn, setIsSpeakerOn] = useState(true);
  const [mediaStream, setMediaStream] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [masterGainNode, setMasterGainNode] = useState(null);
  const [audioLevel, setAudioLevel] = useState(0);
  const animationFrameRef = useRef();
  const analyserRef = useRef(null);
  const audioNodesRef = useRef(new Map());

  const handleMediaStream = async (action) => {
    try {
      if (action === 'start') {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setMediaStream(stream);
        return stream;
      } else if (action === 'stop' && mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
        setMediaStream(null);
      }
    } catch (error) {
      console.error("Error accessing media devices:", error);
      setIsMicOn(false);
    }
  };

  const toggleMic = async () => {
    if (!isMicOn) {
      await handleMediaStream('start');
      setIsMicOn(true);
      if (onMicToggle) onMicToggle(true);
    } else {
      await handleMediaStream('stop');
      setIsMicOn(false);
      if (onMicToggle) onMicToggle(false);
    }
  };

  const toggleSpeaker = () => {
    setIsSpeakerOn(prevState => {
      const newState = !prevState;
      if (externalAudio) externalAudio.muted = !newState;

      const mediaElements = [
        ...document.getElementsByTagName('audio'),
        ...document.getElementsByTagName('video')
      ];

      mediaElements.forEach(element => {
        element.muted = !newState;
        if (audioContext && !audioNodesRef.current.has(element)) {
          const source = audioContext.createMediaElementSource(element);
          audioNodesRef.current.set(element, source);
          if (newState) source.connect(audioContext.destination);
        }
      });

      return newState;
    });
  };

  // Add this function to check mic permissions
  const checkMicPermissions = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'microphone' });
      return result.state === 'granted';
    } catch (error) {
      console.error("Error checking mic permissions:", error);
      return false;
    }
  };

  // Add this effect to monitor permissions changes
  useEffect(() => {
    const permissionCheck = async () => {
      const hasPermission = await checkMicPermissions();
      if (!hasPermission && isMicOn) {
        setIsMicOn(false);
        handleMediaStream('stop');
      }
    };

    permissionCheck();
    
    // Set up permission change listener
    navigator.permissions?.query({ name: 'microphone' })
      .then(permissionStatus => {
        permissionStatus.onchange = () => permissionCheck();
      });
  }, []);

  useEffect(() => {
    const syncWithLiveKit = async () => {
      const hasPermission = await checkMicPermissions();
      if (hasPermission) {
        await handleMediaStream('start');
        setIsMicOn(true);
        if (onMicToggle) onMicToggle(true);
      }
    };

    syncWithLiveKit();
    
    return () => {
      handleMediaStream('stop');
      if (onMicToggle) onMicToggle(false);
    };
  }, []);

  // Adjust the audio level based on the volume prop
  useEffect(() => {
    if (audioContext && masterGainNode) {
      masterGainNode.gain.setValueAtTime(volume / 50, audioContext.currentTime); // Normalize volume to 0-1 range
    }
  }, [volume, audioContext, masterGainNode]);

  // Custom Mic Icon with visualization
  const CustomMicIcon = () => (
    <div style={{ position: 'relative', width: '24px', height: '24px' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '24px', height: '24px' }}>
        <svg width="24" height="24" viewBox="0 0 24 24">
          {/* Background mic shape (outline) */}
          <path
            d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"
            fill="none"
            stroke="currentColor"
          />
          {/* Liquid fill using clip-path */}
          <path
            d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"
            fill="#1976d2"
            style={{
              clipPath: `inset(${100 - (audioLevel * 100)}% 0 0 0)`,
              transition: 'clip-path 0.05s linear',
              opacity: 0.8,
            }}
          />
          {/* Stand part of the mic */}
          <path
            d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"
            fill="currentColor"
          />
        </svg>
      </div>
      {!isMicOn && (
        <div style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0,
          width: '24px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{
            width: '2px',
            height: '24px',
            background: 'currentColor',
            transform: 'rotate(45deg)',
          }} />
        </div>
      )}
    </div>
  );

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: getResponsiveSize('3px', '8px', '10px', '12px'),
        width: '100%',
        flexDirection: isMobile ? 'row' : 'row',
        padding: isMobile ? '0px' : '0',
      }}
    >
      {/* Wrap both controls in a single Box for mobile view */}
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'row' : 'row', alignItems: 'center', gap: '2px', fontSize: isMobile ? '0.25rem' : '1rem' }}>
        {/* Mic Control with custom icon */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <IconButton onClick={toggleMic} size="small">
            {isMicOn ? <CustomMicIcon /> : <MicOffIcon />}
          </IconButton>
          <Switch
            checked={isMicOn}
            onChange={toggleMic}
            size={getResponsiveSize('small', 'small', 'medium', 'medium')}
            color="primary"
          />
        </Box>

        {/* Speaker Control */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <IconButton onClick={toggleSpeaker} size="small">
            {isSpeakerOn ? <img src={VolumeUpIcon} ></img> : <VolumeOffIcon />}
          </IconButton>
          <Switch
            checked={isSpeakerOn}
            onChange={toggleSpeaker}
            size={getResponsiveSize('small', 'small', 'medium', 'medium')}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MicAndSpeaker;