import React from "react";
import { motion } from "framer-motion";
import { Hexagon } from "lucide-react";
import {
  ChevronRight,
  CheckCircle,
  TrendingUp,
  Users,
  Settings,
  Briefcase,
  Handshake,
  Target,
  Lightbulb,
  Gamepad,
  Rocket,
} from "lucide-react";

const Slide = ({ data, template }) => {
  const getTemplateStyles = () => {
    switch (template) {
      case "Modern":
        return "bg-gradient-to-r from-blue-500 to-indigo-600 text-white";
      case "Classic":
        return "bg-gradient-to-r from-gray-100 to-gray-300 text-gray-800";
      case "Minimalist":
        return "bg-white text-gray-800";
      default:
        return "bg-white text-black";
    }
  };

  const icons = [
    { icon: Settings, color: "bg-blue-500" },
    { icon: Briefcase, color: "bg-sky-500" },
    { icon: Handshake, color: "bg-emerald-500" },
    { icon: Target, color: "bg-amber-400" },
    { icon: Lightbulb, color: "bg-orange-500" },
    { icon: Gamepad, color: "bg-rose-500" },
  ];

  const renderContent = () => {
    switch (data.layout) {
      case "coverSlide":
        return (
          <div className="h-full w-full flex">
            {/* Left section with peach background */}
            <div className="w-[45%] bg-[#0096FF] flex items-center justify-center">
              <Hexagon className="w-32 h-32 text-gray-600" strokeWidth={1.5} />
            </div>

            {/* Right section with white background */}
            <div className="flex-1 bg-white flex flex-col justify-center items-center text-center">
              <motion.h1
                className="text-5xl font-bold leading-tight text-gray-800"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {data.title}
              </motion.h1>
            </div>
          </div>
        );

      case "quadrantOverview":
        return (
          <div className="h-full w-full">
            {/* Blue banner with title */}
            <div className="bg-blue-600 py-8 sm:py-12 mb-8 sm:mb-12 ">
              <motion.h2
                className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {data.title}
              </motion.h2>
            </div>
            {/* Content grid */}
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-12">
                {data.content
                  .slice(
                    0,
                    window.innerWidth < 640 ? 3 : data.content.length - 1
                  ) // Display only 3 items on mobile
                  .map((item, index) => (
                    <motion.div
                      key={index}
                      className="flex items-start space-x-4 sm:space-x-6 py-4 sm:py-6"
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5, delay: 0.1 * index }}
                    >
                      <div
                        className={`${icons[index]?.color} p-4 rounded-full shrink-0`}
                      >
                        {icons[index] &&
                          React.createElement(icons[index].icon, {
                            className: "w-6 h-6 sm:w-8 sm:h-8 text-white",
                          })}
                      </div>
                      <div className="flex-1">
                        <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-2 sm:mb-3">
                          {item.title}
                        </h3>
                        <p className="text-gray-600 leading-relaxed text-sm sm:text-base">
                          {item.description}
                        </p>
                      </div>
                    </motion.div>
                  ))}
              </div>
            </div>
          </div>
        );

      case "bulletedList":
        return (
          <div className="h-full w-full flex flex-col">
            {/* Blue banner with problem statement */}
            <div className="bg-blue-600 w-full p-4 sm:p-6 flex flex-col justify-center">
              <div className="container mx-auto px-4 mt-2 sm:mt-4">
                <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-left text-white tracking-tight">
                  Problem Statement
                </h2>
              </div>
              {/* Main problem description */}
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="mb-8 sm:mb-12"
              >
                <p className="text-sm sm:text-base md:text-lg lg:text-xl text-left text-gray-200 leading-relaxed max-w-2xl mx-auto">
                  {data.description}
                </p>
              </motion.div>
            </div>

            <div className="container mx-auto px-2 sm:px-4 mb-8 sm:mb-16 py-4 w-full flex-grow">
              {/* Four square cards */}
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4 lg:gap-6">
                {data.content.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 + index * 0.1 }}
                    className="relative p-2 sm:p-4 rounded-xl border border-[#ff4d6d] shadow-md bg-white"
                  >
                    <div className="flex flex-col items-center text-center space-y-2">
                      {/* Icon Section */}
                      <div className="p-2">
                        <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gradient-to-r from-pink-500 to-red-500 flex items-center justify-center">
                          <span className="text-white font-bold text-sm sm:text-base">
                            ✓
                          </span>
                        </div>
                      </div>

                      {/* Title Section */}
                      <h3 className="text-xs sm:text-sm md:text-base text-black font-extrabold	">
                        {item.title}
                      </h3>

                      {/* Description Section */}
                      <p className="text-xs sm:text-sm md:text-base text-gray-600">
                        {item.description}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        );

      case "splitContent":
        return (
          <div className="h-full w-full">
            {/* Blue banner */}
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Solution Overview
              </motion.h2>
            </div>

            {/* Main content */}
            <div className="container mx-auto px-4">
              <div className="relative">
                {/* Background decoration */}
                <div className="absolute right-0 top-0 w-1/4 sm:w-1/3 h-full bg-indigo-600 rounded-r-[40px] sm:rounded-r-[80px] opacity-10" />

                {/* Content */}
                <div className="relative">
                  {data.content.slice(0, 3).map((item, index) => (
                    <motion.div
                      key={index}
                      className="mb-8 bg-white rounded-2xl shadow-lg p-5 max-w-3xl mr-auto"
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 0.1 * index }}
                    >
                      <div className="flex items-start gap-6">
                        <div
                          className={`${
                            icons[index]?.color || "bg-blue-100 text-blue-600"
                          } p-4 rounded-2xl`}
                        >
                          {icons[index]
                            ? React.createElement(icons[index].icon, {
                                className: "w-6 h-6",
                              })
                            : null}
                        </div>
                        <div>
                          <h4 className="text-xl font-semibold text-gray-800 mb-2">
                            {item.title}
                          </h4>
                          <p className="text-gray-600">{item.description}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case "profileCard":
        return (
          <div className="h-full w-full">
  {/* Blue Banner */}
  <div className="bg-blue-600 py-10">
    <motion.h2
      className="text-3xl sm:text-4xl md:text-5xl font-bold text-center text-white"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      User Persona
    </motion.h2>
  </div>

  {/* Content Section */}
  <div className="flex flex-col sm:flex-row gap-8 items-center justify-center flex-grow px-4 sm:px-12 py-8 rounded-xl">
    {/* Left Card */}
    <motion.div
      className="w-full sm:w-1/2 md:w-1/3 max-w-xs bg-white p-6 rounded-2xl shadow-md text-center"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-28 h-28 mx-auto bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full mb-6 flex items-center justify-center shadow-md">
        <Users className="w-12 h-12 sm:w-16 sm:h-16 text-white" />
      </div>
      <h3 className="text-lg sm:text-xl font-bold mb-4 text-gray-900">
        {data.content[0].description}
      </h3>
      <p className="text-sm sm:text-base text-gray-700">
        {data.content[1].description}
      </p>
    </motion.div>

    {/* Right List of Items */}
    <div className="w-full sm:w-2/3 max-w-2xl space-y-4 py-2">
      {data.content.slice(0, 3).map((item, index) => (
        <motion.div
          key={index}
          className="bg-white p-4 sm:p-6 rounded-lg shadow-md transform hover:translate-y-1 transition-all duration-300"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.1 * (index + 1) }}
        >
          <h4 className="text-md sm:text-lg font-semibold mb-2 text-gray-800">
            {item.title}
          </h4>
          <p className="text-sm sm:text-base text-gray-600">
            {item.description}
          </p>
        </motion.div>
      ))}
    </div>
  </div>
</div>

        );

      case "iconGrid":
        return (
          <div className="w-full h-full">
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-3xl sm:text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Key Features
              </motion.h2>
            </div>
            <div className="px-4 sm:px-8 lg:px-16">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 py-4">
                {data.content.map((item, index) => (
                  <motion.div
                    key={index}
                    className="bg-white bg-opacity-90 p-6 rounded-xl shadow-lg flex flex-col items-center text-center border-2 border-blue-600"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.1 * index }}
                  >
                    <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                      <CheckCircle className="w-8 h-8 text-white" />
                    </div>
                    <h3 className="text-xl sm:text-2xl font-bold mb-4 text-gray-800">
                      {item.title}
                    </h3>
                    <p className="text-base sm:text-lg text-gray-800">
                      {item.description}
                    </p>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        );

      case "processFlow":
        return (
          <div className="w-full h-full">
  {/* Header Section */}
  <div className="bg-blue-600 py-10">
    <motion.h2
      className="text-3xl sm:text-4xl md:text-5xl font-bold text-center text-white"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      User Journey
    </motion.h2>
  </div>

  {/* Content Section */}
  <div className="container mx-auto px-4 relative mt-16">
    <div className="flex flex-col sm:flex-row flex-wrap justify-center gap-8 mt-4">
      {data.content.map((item, index) => (
        <motion.div
          key={index}
          className="relative"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.1 * index }}
        >
          {/* Circle Card */}
          <div className="w-48 h-48 sm:w-56 sm:h-56 bg-white rounded-full p-6 shadow-lg hover:shadow-xl transition-shadow flex flex-col justify-center items-center text-center border border-blue-600">
            {/* Icon */}
            <div className="mb-4">
              <Rocket className="w-10 h-10 sm:w-12 sm:h-12 text-blue-500" />{" "}
            </div>

            {/* Title */}
            <h3 className="text-base sm:text-lg font-semibold text-gray-800 mb-2">
              {item.title}
            </h3>

            {/* Description */}
            <p className="text-sm sm:text-base text-gray-600 leading-relaxed">
              {item.description}
            </p>
          </div>

          {/* Chevron Icon */}
          {index < data.content.length - 1 && (
            <div className="absolute top-auto left-1/2 sm:top-1/2 sm:left-auto sm:right-[-3rem] transform -translate-x-1/2 sm:-translate-y-1/2 z-10">
              <ChevronRight className="w-6 h-6 sm:w-8 sm:h-8 text-blue-400 transform rotate-90 sm:rotate-0" />
            </div>
          )}
        </motion.div>
      ))}
    </div>
  </div>
</div>

        );

      case "timeline":
        return (
          <div className="w-full h-full">
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Project Timeline
              </motion.h2>
            </div>
            <div className="flex flex-col p-8">
              {data.content.slice(0, 3).map((item, index) => (
                <motion.div
                  key={index}
                  className="flex mb-8"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  <div className="w-full sm:w-1/4 pr-0 sm:pr-4 flex flex-row sm:flex-col items-center mb-4 sm:mb-0">
                    <div className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold mr-4 sm:mr-0 sm:mb-2">
                      {index + 1}
                    </div>
                    <h3 className="text-xl font-bold text-center text-gray-800">
                      {item.title}
                    </h3>
                  </div>
                  <div className="w-full sm:w-3/4 bg-white bg-opacity-90 p-6 rounded-lg shadow-lg border border-blue-600">
                    <p className="text-lg text-gray-800">{item.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        );

      case "metricCards":
        return (
          <div className="w-full h-full">
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-3xl sm:text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Success Metrics
              </motion.h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 flex-grow p-4">
              {data.content.slice(0, 3).map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-white bg-opacity-90 p-6 rounded-xl shadow-lg flex flex-col items-center text-center border border-blue-600"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  <div className="w-14 h-14 sm:w-16 sm:h-16 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                    <TrendingUp className="w-6 h-6 sm:w-8 sm:h-8 text-white" />
                  </div>
                  <h3 className="text-xl sm:text-2xl font-bold mb-4 text-gray-800">
                    {item.title}
                  </h3>
                  <p className="text-base sm:text-xl font-bold text-gray-600">
                    {item.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="h-full">
      <div className="w-full h-full  mx-auto bg-white bg-opacity-90 backdrop-blur-lg rounded-xl shadow-2xl overflow-hidden">
        {renderContent()}
      </div>
    </div>
  );
};

export default Slide;
