import * as React from "react";
import { Image } from "./Image";
import { ActionButton } from "./ActionButton";

export const ConfirmationMessage = ({ onClose, onAction , type}) => {
  const [isChatModalOpen, setIsChatModalOpen] = React.useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);
  
  // Define actions based on the type
  const actions = {
    Share: {
      heading:"Connection with an Expert Confirmed!",
      content:"An email has been sent to your expert with further instructions.",
      textp:"Meanwhile, get insights by sharing with someone you know.",
      icon: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/522741582e7729cc6edfd4ec91f4772fbca57031ff61bafd2c22cb6995324544?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      text: "Share",
      handleAction: () => {
        onAction("Share"); // Open InviteCollaborator modal
        onClose(); // Close ConfirmationMessage modal
      },
    },
    Chat: {
      heading:"Invitation Sent Successfully!",
      content:"We’ll notify you about the next steps and updates.",
      textp:"Meanwhile, get insights by chatting with an expert.",
      icon: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/06d1ec34652a9423705a70f55f6e6ef860df040bf4c5c0f1da9f4b57e46041f9?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      text: "Chat with an Expert",
      handleAction: () => {
        onAction("Chat"); // Open ExpertChat modal
        onClose(); // Close ConfirmationMessage modal
      },
    },
  };
  const selectedAction = actions[type];
  return (
    <div className="flex flex-col text-xl font-semibold rounded-none min-w-[200px] sm:min-w-[200px] md:min-w-[600px] xl:min-w-[793px] xl:max-w-[793px] lg:min-w-[793px] lg:max-w-[793px] text-neutral-900">
      <div className="flex flex-col items-center px-7 xl:pt-6 xl:pb-20 lg:pt-6 lg:pb-20 md:pt-4 md:pb-16 sm:pt-4 xl:pb-16 pt-2 pb-8 w-full bg-white rounded-2xl max-md:px-5 max-md:max-w-full">
        {/* Close Button */}
        <button
          className="self-end w-6 h-6 cursor-pointer"
          onClick={onClose}
          aria-label="Close"
        >
          <span className="text-xl font-semibold">✕</span>
        </button>
        <Image
          src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/37915bbd5eb489794ef46b88f0e9fe90f7b41ce46f42f710b1734ceae23e800a?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
          alt="Success confirmation icon"
          className="mt-8 max-w-full aspect-square xl:w-[118px] lg:w-[118px] md:w-[100px] sm:w-[80px] w-[60px]"
        />
        <h1 className="mt-9 xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl text-lg max-md:max-w-full max-sm:pl-6">
          {selectedAction.heading}
        </h1>
        <p className="xl:mt-8 lg:mt-8 md:mt-6 sm:mt-4 mt-2 xl:text-2xl lg:text-2xl md:text-xl sm:text-lg text-sm text-neutral-500 max-md:max-w-full max-sm:pl-7 px-4">
          {selectedAction.content}
        </p>
        <p className="xl:mt-12 lg:mt-12 md:mt-8 sm:mt-6 mt-6 xl:text-2xl lg:text-2xl md:text-xl sm:text-lg text-sm text-center max-md:mt-10 max-md:max-w-full">
          {selectedAction.textp}
        </p>
        <div className="shrink-0 self-stretch xl:mt-16 lg:mt-16 md:mt-12 sm:mt-8 mt-6 w-full h-px max-md:mt-10 max-md:mr-1" />

       {/* Action Button */}
             {selectedAction && (
               <ActionButton
                 text={selectedAction.text}
                 icon={selectedAction.icon}
                 onClick={()=> {selectedAction.handleAction(); onClose(); }}
               />
             )}
      </div>
    </div>
  );
};
