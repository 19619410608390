import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import loginframe from "../assets/loginframe.jpg";
import smloginframe from "../assets/smloginframe.jpg";
import { IoMdArrowRoundBack } from "react-icons/io";
import config from "../url_config/urls_config";
import GoogleLoginButton from "../components/button/Google_button/GoogleLoginButton";
const BASE_URL = config.getRq_api();

const LoginPage = () => {
  const [emailEntered, setEmailEntered] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [error, setError] = useState(null);
  const [otpError, setOtpError] = useState(false); // State to track OTP submission error
  const navigate = useNavigate();
  const location = useLocation();
  const [loginloading, setloginLoading] = useState(false);
  const [isSendagainLoading, setisSendagainLoading] = useState(false);
  const [resend, setresend] = useState("Resend");
  const firstOtpInputRef = useRef(null);
  const [resendclick, setRessendclick] = useState(false);

  useEffect(() => {
    if (emailEntered && firstOtpInputRef.current) {
      firstOtpInputRef.current.focus(); // Focus on the first OTP input
    }
  }, [emailEntered]); // Focus when emailEntered is true

  const navigateCallback = useCallback(
    () => navigate("/dashboard"),
    [navigate]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlUsername = params.get("username");
    const urlToken = params.get("token");
    const urlName = params.get("name");
    const urlRoomName = params.get("room_name");
    const urlSp = params.get("sp");

  if (urlUsername && urlToken && urlName) {
    localStorage.setItem("username", urlUsername);
    localStorage.setItem("token", urlToken);
    localStorage.setItem("name", urlName);
    localStorage.setItem("room_name", urlRoomName);
    navigateCallback(); // Use memoized callback
  }
  else{
     navigate("/")
  }

    if (urlSp) {
      localStorage.setItem("sp", urlSp);
    }
  }, [navigateCallback]);

  useEffect(() => {
    // Check if state is passed from the previous component
    if (location.state) {
      setEmailEntered(location.state.emailEntered || false);
      setEmail(location.state.email || "");
      console.log("this is base url", BASE_URL);
    }
  }, [location.state]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setloginLoading(true); // Show loader

    try {
      // Call the API with Axios
      const response = await axios.post(`${BASE_URL}/auth/update-password/`, {
        email: email, // Pass the email as a request body
      });

      // Check if the response status is 200 (successful)
      if (response.status === 200) {
        // Handle successful response
        console.log("this is the response", response);
        console.log("Password update success:", response.data);

        // Update hooks only on success
        setloginLoading(false);
        console.log("this is send again loader : ", isSendagainLoading);
        if (isSendagainLoading) {
          setisSendagainLoading(false);
          setresend("Resent");
          // setRessendclick(false)
        }
        setEmailEntered(true);
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        // Server responded with a status other than 2xx
        if (error.response.status === 404) {
          setloginLoading(false);
          alert("User not found. Please register..!");
          navigate("/signup");
        } else if (error.response.status === 500) {
          console.error(
            "Error: Unexpected server error",
            error.response.data.detail
          );
          alert(
            "An unexpected error occurred on the server. Please try again later."
          );
        } else {
          console.error(
            "Error: API responded with an error",
            error.response.data.detail
          );
          alert(
            `Error: ${error.response.data.detail || "Something went wrong"}`
          );
        }
      } else if (error.request) {
        // No response received from the server
        console.error("Error: No response received from server", error.request);
        alert("No response from server. Please check your network connection.");
      } else {
        // Other errors (e.g., Axios configuration)
        console.error("Error: Request setup error", error.message);
        alert("An unexpected error occurred. Please try again.");
      }

      // If error occurs, reset loading state
      setloginLoading(false);
      setisSendagainLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const enteredOtp = otp.join("");
    try {
      const response = await fetch(`${BASE_URL}/auth/login/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, otp: enteredOtp }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Data received: ", data);
        localStorage.setItem("token", data?.token);
        localStorage.setItem("name", data?.name);
        localStorage.setItem("username", email);
        localStorage.setItem("room_name", data?.room_name);
        localStorage.setItem("narration_room_name", data?.narration_room_name);
        const proj_id = localStorage.getItem("sp");
        setError(null);

        if (!data?.user_details_exist) {
          navigate("/register");
        } else if (!data?.project_exist || proj_id) {
          navigate("/interstitial");
        } else if (data?.user_details_exist && data?.project_exist) {
          navigate("/dashboard");
        }
      } else {
        setOtpError(true);
        setError("Invalid OTP Entered" || "Login failed.");
      }
    } catch {
      setError("An unexpected error occurred.");
    }
  };

  const handleOtpChange = (value, index) => {
    // Handle paste event with multiple characters
    if (value.length > 1) {
      const digits = value.slice(0, 8).split("");
      const newOtp = [...otp];
      digits.forEach((digit, idx) => {
        if (idx < 8) {
          newOtp[idx] = digit;
        }
      });
      setOtp(newOtp);
    } else {
      // Handle single character input (existing behavior)
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input box if a character is entered and it's not the last box
      if (value && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }

    // Check if all OTP fields are filled and auto-submit
    if (otp.every((digit) => digit.trim() !== "")) {
      handleOtpSubmit(); // Auto-submit when all OTP fields are filled
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 8);
    const digits = pastedData.split("");
    const newOtp = [...otp];

    digits.forEach((digit, idx) => {
      if (idx < 8) {
        newOtp[idx] = digit;
      }
    });

    setOtp(newOtp);
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      if (otp[index]) {
        // Clear the current box
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        // Move focus to the previous box if it's empty
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) prevInput.focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) prevInput.focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };

  // Function to handle the "Back" button
  const handleBackClick = () => {
    setEmailEntered(false); // Set emailEntered to false to show email input again
    setRessendclick(false);
  };

  // Detect when OTP is fully filled and submit automatically
  useEffect(() => {
    if (otp.every((digit) => digit.trim() !== "")) {
      handleOtpSubmit(new Event("submit")); // Trigger the OTP submit
    }
  }, [handleOtpSubmit, otp]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-[#99B5D4] to-[#DDCFE8] p-4">
      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg w-full max-w-4xl overflow-hidden">
        <div className="w-full md:w-1/2 order-last md:order-last">
          {/* Image for md and above */}
          <img
            src={loginframe}
            alt="signup"
            className="w-full h-full object-cover hidden md:block md:-mb-0"
          />
          ``
          {/* Image for below md */}
          <img
            src={smloginframe}
            alt="signup"
            className="w-full h-full object-cover rotate-90 -mb-24 p-10 md:hidden"
          />
        </div>

        <div className="w-full md:w-1/2 flex flex-col justify-center px-6 md:px-12 py-8">
          <div className="flex items-center justify-start mb-4">
            {emailEntered && (
              <strong
                className="cursor-pointer flex items-center underline"
                onClick={handleBackClick} // Attach the handleBackClick function
              >
                <IoMdArrowRoundBack className="mr-2" /> Back
              </strong>
            )}
          </div>

          <h1 className="text-3xl  text-blue-700 mb-4 font-extrabold font-monst">
            Login
          </h1>
          <p className="text-gray-500 mb-6 text-xs font-semibold font-robo">
            Welcome to RQ, Let's continue building your project's success.
          </p>

          {!emailEntered ? (
            <div>
              <form onSubmit={handleEmailSubmit}>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="border-b-2 focus:outline-none focus:border-black mb-4 pb-2 w-full bg-white text-black mt-8 font-robo "
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    e.target.setCustomValidity(""); // Reset custom validity on change
                  }}
                  onInvalid={(e) => {
                    e.target.setCustomValidity("Invalid Email Address."); // Custom error message
                  }}
                  required
                />
                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                <button
                  type="submit"
                  className={`border-solid border-2 ${
                    loginloading
                      ? "border-gray-400 bg-gray-300 text-gray-600"
                      : "border-sky-500 text-LoginBlue hover:bg-blue-500 hover:text-white font-robo"
                  } w-full px-6 py-2 rounded font-extrabold`}
                  disabled={loginloading} // Disable the button while loading
                >
                  {loginloading ? "Logging in..." : "Login"}
                </button>

                <div className="my-4 flex items-center">
                  <hr className="flex-grow border-t border-gray-400" />
                  <span className="mx-2 text-gray-500">or</span>
                  <hr className="flex-grow border-t border-gray-400" />
                </div>
              </form>
              <div className="flex justify-center w-full">
                <GoogleLoginButton
                  buttonText="Continue with Google"
                  className="font-robo"
                />
              </div>
            </div>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault(); // Prevent form submission by default
                if (otp.some((digit) => digit.trim() === "")) {
                  // Check if any OTP field is empty
                  alert("Please enter the full 6-digit OTP."); // Show a popup
                  return; // Exit without calling the API
                }
                handleOtpSubmit(e); // Call the API if OTP is valid
              }}
            >
              <p className="text-gray-500 mb-4 font-robo">
                Please enter the 6-digit code we sent to:{" "}
                <strong className="text-sm font-extrabold text-black font-robo">
                  {email}
                </strong>
              </p>
              <div className="flex justify-between mb-4">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    ref={index === 0 ? firstOtpInputRef : null}
                    type="text"
                    maxLength="1"
                    className={`border-2 rounded-md text-center text-black w-10 lg:w-10 sm:w-10 md:w-7 md:m-0.5 h-10 bg-white focus:outline-none ${
                      otpError ? "border-red-500" : "focus:border-black"
                    }`} // Conditionally apply red border
                    value={value}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    onPaste={(e) => handlePaste(e, index)}
                  />
                ))}
              </div>
              {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center ">
                  <strong
                    className="underline cursor-pointer mr-2"
                    onClick={(e) => {
                      setRessendclick(true);
                      setisSendagainLoading(true); // Show the loader
                      handleEmailSubmit(e);

                      // Simulate API call completion and hide loader after a delay (you can adjust the delay)
                      setTimeout(() => {
                        setisSendagainLoading(false); // Hide the loader and show the checkmark
                      }, 2000); // 2 seconds for the loader duration
                    }}
                  >
                    {resend}
                  </strong>
                  {isSendagainLoading ? (
                    <span className="ml-2">
                      <svg
                        className="animate-spin h-4 w-4 text-blue-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  ) : resendclick ? (
                    <span className="ml-1 text-green-500 ">&#10004;</span> // Right mark when loading is done
                  ) : null}
                </div>
                <button
                  type="submit"
                  className="border-solid border-2 border-sky-500 text-LoginBlue font-robo px-6 py-2 rounded hover:bg-blue-500 hover:text-white"
                >
                  Continue
                </button>
              </div>
            </form>
          )}
          <p className="text-gray-500 mt-4 text-sm text-left">
            New User?
            <strong
              className="text-blue-600 underline font-extrabold ml-2 cursor-pointer"
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
