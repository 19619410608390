import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import audioFile from "../assets/Hello and welcome Th.mp3";
import rafiki from "../components/IntertesialPage/rafiki.svg";
import { MdError } from "react-icons/md";
import { CiMicrophoneOn, CiMicrophoneOff } from "react-icons/ci";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { RxSpeakerOff } from "react-icons/rx";
import { FaPauseCircle } from "react-icons/fa";
import { BsPlayCircleFill } from "react-icons/bs";
import config from "../url_config/urls_config";

const BASE_URL = config.getRq_api();

const InterstitialPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, room_name, chat } = location.state || {};
  const { projectId } = location.state || {};
  const [imageSrc, setImageSrc] = useState(rafiki);
  const [text, setText] = useState("We're building your workspace.");
  const [showMicSpeakerBox, setShowMicSpeakerBox] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [audioInstance, setAudioInstance] = useState(null);
  const [isMicOn, setIsMicOn] = useState(false); // Default state to false, will check permission
  const [isSpeakerOn, setIsSpeakerOn] = useState(true); // Default to on
  const [isPlaying, setIsPlaying] = useState(false); // State to track whether audio is playing
  const [isOpen, setIsOpen] = useState(false);
  const micStreamRef = useRef(null); // Store microphone stream
  const contentRef = useRef(null);

  // Check if microphone permission is granted
  useEffect(() => {
    navigator.permissions
      .query({ name: "microphone" })
      .then((permissionStatus) => {
        setIsMicOn(permissionStatus.state === "granted"); // Set mic state based on permission
      })
      .catch((err) => {
        console.error("Permission query failed:", err);
      });
  }, []);

  // Request microphone access and manage the stream
  useEffect(() => {
    if (isMicOn) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          micStreamRef.current = stream;
        })
        .catch((err) => {
          console.error("Microphone access denied:", err);
        });
    } else {
      if (micStreamRef.current) {
        micStreamRef.current.getTracks().forEach((track) => track.stop());
        micStreamRef.current = null;
      }
    }
  }, [isMicOn]);

  // To manage the audio playback and mute functionality
  const playSound = () => {
    if (audioInstance) {
      audioInstance.pause();
      audioInstance.currentTime = 0;
    }

    const audio = new Audio(audioFile);
    setAudioInstance(audio);

    audio.muted = !isSpeakerOn; // Mute the audio if speaker is off
    audio.play();
    setIsPlaying(true); // Set the state to indicate audio is playing
  };

  const pauseSound = () => {
    if (audioInstance) {
      audioInstance.pause(); // Pause the audio playback
      audioInstance.volume = 0; // Mute the tab's audio
      setIsPlaying(false); // Update the state to reflect the audio is paused
    }
  };

  const toggleMic = () => {
    if (isMicOn) {
      setIsMicOn(false);
    } else {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          setIsMicOn(true);
        })
        .catch(() => {
          alert("Please enable microphone access to use this feature.");
        });
    }
  };

  const toggleSpeaker = () => {
    setIsSpeakerOn((prev) => !prev); // Toggle speaker state
  };

  const handleGetStarted = async () => {
    const proj_id = localStorage.getItem("sp");
    const token = localStorage.getItem("token"); // Get the token from localStorage

    if (proj_id) {
      const response = await fetch(`${BASE_URL}/api/decrypt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
        body: JSON.stringify({ encrypted_data: proj_id }),
      });

      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem("feedback_room", data.feedback_room);
        console.log("this is data", data.decrypted_data);
        
        navigate("/dashboard", {
          state: {
            projid: data.decrypted_data,
            name,
            room_name,
            chat,
          },
        });
      }
    }
    else if(projectId){
      const response = await fetch(`${BASE_URL}/api/project/get-room-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
        body: JSON.stringify({ 
          token: localStorage.getItem("token"),
          projectId: projectId
        }),
      });
      const data = await response.json();
      if (data.status === 200) {
      //   localStorage.setItem("feedback_room", data.room);
      // navigate("/brainstorm/", {
      //   state: {
      //     projectid: projectId,
      //     usecaseType: "feedback",
      //     name,
      //     room_name,
      //     chat,
      //   },
      // });
    }
    }
    else{
      navigate(`/brainstorm/`, {
        state: {
          name,
          room_name,
          chat,
        },
    })
  };
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageSrc((prevSrc) =>
        prevSrc === rafiki
          ? "https://c.animaapp.com/qM5VqOWz/img/visionary-technology-rafiki@2x.png"
          : rafiki
      );
      setText((prevText) =>
        prevText === "We're building your workspace."
          ? "Just a moment to get everything perfect!"
          : prevText === "Just a moment to get everything perfect!"
          ? "Sit in a quiet environment!"
          : "We're building your workspace."
      );
      setShowMicSpeakerBox(true);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 4800);

    return () => clearTimeout(loadingTimer);
  }, []);

  const handleHavingTrouble = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="flex justify-center items-center  min-h-screen bg-gradient-to-r from-[#99B5D4] to-[#DDCFE8] p-4">
      <div className="relative flex flex-col items-center gap-4 w-full max-w-[800px]">
        <img
          src={imageSrc}
          alt="Visionary Technology"
          className="w-24 md:w-32 lg:w-40 h-auto animate-bounce"
        />
        <p className="text-center text-xl md:text-2xl lg:text-3xl font-roboto px-4 text-black font-robo ">
          {text}
        </p>
        <div className="flex gap-16 m-10">
          {/* Mic icon and toggle */}
          <div className="flex items-center gap-2">
            {isMicOn ? (
              <CiMicrophoneOn className="text-4xl font-bold text-black" />
            ) : (
              <CiMicrophoneOff className="text-4xl font-bold text-black" />
            )}
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isMicOn}
                onChange={toggleMic}
                className="hidden"
              />
              <span
                className={`w-10 h-5 rounded-full relative transition-colors duration-300 ${
                  isMicOn ? "bg-blue-500" : "bg-gray-400"
                }`}
              >
                <span
                  className={`absolute left-0 top-0 w-5 h-5 bg-white rounded-full transition-transform duration-300  ${
                    isMicOn ? "transform translate-x-full bg-blue-500" : ""
                  }`}
                ></span>
              </span>
            </label>
          </div>

          {/* Speaker icon and toggle */}
          <div
            className="flex items-center gap-2"
            onClick={() => {
              if (audioInstance) {
                audioInstance.pause();
              }
              setIsPlaying(false);
            }}
          >
            {isSpeakerOn ? (
              <HiOutlineSpeakerWave className="text-4xl font-bold text-black" />
            ) : (
              <RxSpeakerOff className="text-4xl font-bold text-black" />
            )}
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isSpeakerOn}
                onChange={toggleSpeaker}
                className="hidden"
              />
              <span
                className={`w-10 h-5 rounded-full relative transition-colors duration-300 ${
                  isSpeakerOn ? "bg-blue-500" : "bg-gray-400"
                }`}
              >
                <span
                  className={`absolute left-0 top-0 w-5 h-5 bg-white rounded-full transition-transform duration-300  ${
                    isSpeakerOn ? "transform translate-x-full bg-blue-500" : ""
                  }`}
                ></span>
              </span>
            </label>
          </div>
        </div>

        <button
          onClick={isPlaying ? pauseSound : playSound} // Toggle play and pause
          className="flex items-center gap-2 bg-white/40 hover:bg-white/60 transition-transform transform hover:scale-105 rounded px-4 py-2"
        >
          {isPlaying ? (
            <FaPauseCircle className="text-blue-600 text-2xl" />
          ) : (
            <BsPlayCircleFill className="text-blue-600 text-2xl" />
          )}
          <span className="text-blue-600 text-sm md:text-base lg:text-2xl font-robo">
            {isPlaying
              ? "Pause speaker"
              : "Play test sound to ensure your speaker is working"}
          </span>
        </button>

        <div className="flex flex-col justify-start container mx-auto">
          <div className="w-full flex flex-col items-center">
            <div className="flex items-center justify-center mb-2">
              <MdError className="mr-2 text-LoginBlue" />
              <p
                className="text-LoginBlue underline cursor-pointer hover:opacity-80 transition-opacity"
                onClick={handleHavingTrouble}
              >
                Having trouble?
              </p>
            </div>
          
            <div
              ref={contentRef}
              style={{
                maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : "0",
                transform: `translateY(${isOpen ? '0' : '-10px'})`,
                opacity: isOpen ? 1 : 0,
                overflow: "hidden",
                transition: "max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease-in-out, transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)",
                willChange: "max-height, opacity, transform"
              }}
              className="w-full flex justify-center"
            >
              <div className="bg-[#F1F3FF] p-4 rounded shadow-md max-w-1/2 transition-transform">
                <ul className="list-disc list-inside text-left pb-4 text-black opacity-70 font-robo font-normal">
                  <li>Ensure the volume is turned up and not muted.</li>
                  <li>Check if headphones are plugged in.</li>
                  <li>Verify the correct audio output device is selected.</li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`w-full flex justify-center transition-all duration-500 ease-in-out ${
              isOpen ? "mt-4" : "mt-8"
            }`}
          >
            <button
              onClick={handleGetStarted}
              className="flex items-center gap-2 bg-gradient-to-r from-indigo-500 to-blue-500 text-white hover:from-indigo-600 hover:to-blue-600 transition-transform transform hover:scale-105 rounded px-10 py-3"
            >
              <span className="text-sm md:text-lg lg:text-xl">
                Let's Get Started
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L20.25 12m0 0L13.5 19.5M20.25 12H3.75"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterstitialPage;
