"use client";
import React from "react";
import { LoadingSVG } from "../button/LoadingSVG.tsx";
import { ChatMessageType } from "../chat/ChatTile.tsx";
import { PlaygroundHeader } from "../playground/PlaygroundHeader.tsx";
import { AgentMultibandAudioVisualizer } from "../visualization/AgentMultibandAudioVisualizer.tsx";
import { useConfig } from "../../hooks/useConfig.tsx";
import { useMultibandTrackVolume } from "../../hooks/useTrackVolume.tsx";
import { TranscriptionTile } from "../../transcriptions/TranscriptionTile.tsx";
import { useKrispNoiseFilter } from "@livekit/components-react/krisp";
import {
  TrackReferenceOrPlaceholder,
  useConnectionState,
  useDataChannel,
  useLocalParticipant,
  useRemoteParticipants,
  useRoomInfo,
  useTracks,
} from "@livekit/components-react";
import {
  ConnectionState,
  LocalParticipant,
  RoomEvent,
  Track,
} from "livekit-client";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import "@livekit/components-styles";
import MicAndSpeaker from "../IntertesialPage/Mic_and_speaker.jsx";
import Deck from "../../pages/Deck.jsx";

export interface PlaygroundMeta {
  name: string;
  value: string;
}

export interface PlaygroundProps {
  collaboratorModeActive?: boolean;
  logo?: ReactNode;
  themeColors: string[];
  onConnect: (connect: boolean, opts?: { token: string; url: string }) => void;
  chat?: any;
  onCloseRoom?: (remainingTime: number | null) => void;
  remainingTime: number;
  collaboratorMode: string;
}

const headerHeight = 56;
export default function Playground({
  logo,
  themeColors,
  onConnect,
  chat,
  onCloseRoom,
  remainingTime,
  collaboratorMode
}: PlaygroundProps) {
  const { config, setUserSettings } = useConfig();
  const { name } = useRoomInfo();
  const [transcripts, setTranscripts] = useState<ChatMessageType[]>([]);
  const { localParticipant } = useLocalParticipant();
  const [showChat, setShowChat] = useState(true); // State to toggle chat visibility
  const [currentMessages, setCurrentMessages] = useState<ChatMessageType[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const krisp = useKrispNoiseFilter();
  useEffect(() => {
    krisp.setNoiseFilterEnabled(true);
  }, []);
  useEffect(() => {
    const initializeMedia = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        console.log("Media permissions granted");
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    initializeMedia();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoad(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const participants = useRemoteParticipants({
    updateOnlyOn: [RoomEvent.ParticipantMetadataChanged],
  });
  const agentParticipant = participants.find((p) => p.isAgent);
  const isAgentConnected = agentParticipant !== undefined;

  const roomState = useConnectionState();
  const tracks = useTracks();

  useEffect(() => {
    if (roomState === ConnectionState.Connected) {
      localParticipant.setMicrophoneEnabled(config.settings.inputs.mic);
    }
  }, [config, localParticipant, roomState]);

  let agentAudioTrack: TrackReferenceOrPlaceholder | undefined;
  const aat = tracks.find(
    (trackRef) =>
      trackRef.publication.kind === Track.Kind.Audio &&
      trackRef.participant.isAgent
  );
  if (aat) {
    agentAudioTrack = aat;
  } else if (agentParticipant) {
    agentAudioTrack = {
      participant: agentParticipant,
      source: Track.Source.Microphone,
    };
  }

  const subscribedVolumes = useMultibandTrackVolume(
    agentAudioTrack?.publication?.track,
    5
  );

  const localTracks = tracks.filter(
    ({ participant }) => participant instanceof LocalParticipant
  );
  const localVideoTrack = localTracks.find(
    ({ source }) => source === Track.Source.Camera
  );
  const localMicTrack = localTracks.find(
    ({ source }) => source === Track.Source.Microphone
  );

  const localMultibandVolume = useMultibandTrackVolume(
    localMicTrack?.publication.track,
    20
  );

  const onDataReceived = useCallback(
    (msg: any) => {
      if (msg.topic === "transcription") {
        const decoded = JSON.parse(
          new TextDecoder("utf-8").decode(msg.payload)
        );
        let timestamp = new Date().getTime();
        if ("timestamp" in decoded && decoded.timestamp > 0) {
          timestamp = decoded.timestamp;
        }
        setTranscripts([
          ...transcripts,
          {
            name: "You",
            message: decoded.text,
            timestamp: timestamp,
            isSelf: true,
          },
        ]);
      }
    },
    [transcripts]
  );

  useDataChannel(onDataReceived);

  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    setIsMobile(width <= 768);
    setIsTablet(width > 768 && width <= 1024);
  };

  useEffect(() => {
    handleResize(); // Check on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const audioTileContent = useMemo(() => {
    const disconnectedContent = (
      <div className="flex flex-col items-center justify-center gap-2 text-gray-700 text-center w-full h-screen">
        No audio track. Connect to get started.
      </div>
    );

    const waitingContent = (
      <div className="flex flex-col items-center gap-2 text-gray-700 text-center w-full">
        <LoadingSVG />
        Waiting for audio track
      </div>
    );

    const visualizerContent = (
      <div>
        <AgentMultibandAudioVisualizer
          state="speaking"
          barWidth={isMobile ? 15 : 36}
          minBarHeight={isMobile ? 20 : 40}
          maxBarHeight={isMobile ? 40 : 150}
          accentColor={"#0060C7"}
          accentShade={500}
          frequencies={subscribedVolumes}
          borderRadius={isMobile ? 15 : 20}
          gap={isMobile ? 10 : 15}
        />
      </div>
    );

    if (roomState === ConnectionState.Disconnected) {
      return disconnectedContent;
    }

    if (!agentAudioTrack) {
      return waitingContent;
    }

    return visualizerContent;
  }, [agentAudioTrack, subscribedVolumes, roomState, showChat, isMobile]);

  const chatTileContent = useMemo(() => {
    if (agentAudioTrack) {
      return (
        <TranscriptionTile
          agentAudioTrack={agentAudioTrack}
          accentColor={"cyan"}
          onMessagesUpdate={setCurrentMessages}
          chat={{ conversation: Array.isArray(chat) ? chat : [] }}
        />
      );
    }
    return <></>;
  }, ["cyan", agentAudioTrack, chat]);

  const autoConnect = () => {
    onConnect(roomState === ConnectionState.Disconnected);
  };

  const [micLevel, setMicLevel] = useState(0); // State for microphone level
  const [speakerVolume, setSpeakerVolume] = useState(0); // State for speaker volume

  const handleMicToggle = async (isOn: boolean) => {
    try {
      if (isOn) {
        if (roomState === ConnectionState.Connected) {
          await localParticipant.setMicrophoneEnabled(true);
          setMicLevel(Math.min(micLevel + 1, 20)); // Limit mic level to 20
          console.log("Microphone enabled");
        } else {
          console.error("Cannot enable microphone: not connected to the room.");
        }
      } else {
        await localParticipant.setMicrophoneEnabled(false);
        setMicLevel(0); // Reset mic level when disabled
        console.log("Microphone disabled");
      }
    } catch (error) {
      console.error("Error toggling LiveKit microphone:", error);
    }
  };

  const onVolumeChange = (newVolume: number) => {
    setSpeakerVolume(Math.min(newVolume, 40)); // Limit speaker volume to 50
  };

  // Add new state for mobile controls visibility
  const [showMobileControls, setShowMobileControls] = useState(true); // Initially show mobile controls

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMobileControls(false); // Hide after 10 seconds
    }, 5000); // 10 seconds

    return () => clearTimeout(timer);
  }, []); // Run only on mount

  // Toggle handler for mobile controls
  const handleVisualizerClick = () => {
    if (isMobile) {
      setShowMobileControls(!showMobileControls); // Toggle visibility on click
    }
  };

  return (
    <div className="h-screen relative">
      {/* Mobile Layout */}
      <div className="md:hidden h-full relative">
        {/* Glassy Effect */}
        <div className="absolute top-0 left-0 right-0 h-[10%] backdrop-blur-sm z-40" />

        {/* Main Chat Container */}
        <div className="h-full flex flex-col">
          {/* Chat Messages Area */}
          <div className="h-full flex-grow overflow-y-auto bg-gray-100 hide-scrollbar">
            <div className="flex flex-col h-full relative">
              <div className="flex-grow overflow-y-auto">{chatTileContent}</div>
            </div>
          </div>
        </div>

        {/* Mobile Header - Only visible when showMobileControls is true */}
        <div
          className={`fixed top-12 left-1/2 transform -translate-x-1/2 bg-white shadow-md transition-transform duration-300 z-40  ${
            showMobileControls ? "w-[90%]" : "w-1/4"
          }`}
          style={{ borderRadius: "50px", height: "60px" }}
        >
          <PlaygroundHeader
            title={config.title}
            logo={logo}
            githubLink={config.github_link}
            height={headerHeight}
            accentColor={"cyan"}
            connectionState={roomState}
            chat={chat}
            onConnectClicked={() =>
              onConnect(roomState === ConnectionState.Disconnected)
            }
            messages={currentMessages}
            isAgentConnected={isAgentConnected}
            autoConnect={autoConnect}
            onCloseRoom={onCloseRoom}
            remainingTime={remainingTime}
            isMobile={isMobile}
            collaboratorMode = {collaboratorMode}
          />
        </div>

        {/* Mobile MicAndSpeaker - Only visible when showMobileControls is true */}
        <div
          className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 transition-transform duration-300 z-40 ${
            showMobileControls ? "translate-y-[-50px]" : "translate-y-full"
          }`}
        >
          <div
            className={`bg-white rounded-lg p-2 shadow-lg ${
              isMobile ? "w-full" : "w-52"
            }`}
            style={{ borderRadius: "50px", height: "40px" }}
          >
            <MicAndSpeaker
              getResponsiveSize={(s, m1, m2, l) => s}
              externalAudio={false}
              onMicToggle={handleMicToggle}
              volume={speakerVolume}
              micLevel={micLevel}
              isMobile={isMobile}
            />
          </div>
        </div>

        {/* Clickable Visualizer */}
        <div
          className="fixed top-12 left-1/2 transform -translate-x-1/2 z-50 cursor-pointer"
          onClick={handleVisualizerClick}
        >
          <div
            className="bg-white "
            style={{ width: "140px", height: "60px", borderRadius: "50px" }}
          >
            <div
              className="transition-all duration-1000 ease-in-out h-full flex items-center justify-center"
              style={{ opacity: isInitialLoad ? 0.3 : 1 }}
            >
              {audioTileContent}
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Layout - Unchanged */}
      <div className="hidden md:flex h-screen">
        <div
          className={`flex flex-col ${
            isMobile ? "w-full" : "w-1/3"
          } justify-center items-center`}
          style={{ backgroundColor: "#3c6ad0" }}
        >
          <div
            className={`flex flex-col justify-center items-center bg-white p-4 shadow-lg ${
              isMobile ? "h-auto" : "h-80"
            } w-70`}
            style={{
              borderRadius: "55px",
              width: isMobile ? "90%" : "293px",
              height: isMobile ? "auto" : "294px",
            }}
          >
            <div
              className={`transition-all duration-1000 ease-in-out `}
              style={{
                opacity: isInitialLoad ? 0.3 : 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "150px",
              }}
            >
              {audioTileContent}
            </div>
          </div>
          <div
            className="bg-white rounded-lg p-2 shadow-lg m-3"
            style={{ borderRadius: "50px", width: "208px", height: "53px" }}
          >
            <MicAndSpeaker
              getResponsiveSize={(s, m1, m2, l) => s}
              externalAudio={false}
              onMicToggle={handleMicToggle}
              volume={speakerVolume}
              micLevel={micLevel}
              isMobile={false}
            />
          </div>
        </div>
        <div className="w-full">
          <PlaygroundHeader
            title={config.title}
            logo={logo}
            githubLink={config.github_link}
            height={headerHeight}
            accentColor={"cyan"}
            connectionState={roomState}
            chat={chat}
            onConnectClicked={() =>
              onConnect(roomState === ConnectionState.Disconnected)
            }
            messages={currentMessages}
            isAgentConnected={isAgentConnected}
            autoConnect={autoConnect}
            onCloseRoom={onCloseRoom}
            remainingTime={remainingTime}
            isMobile={isMobile}
            collaboratorMode = {collaboratorMode}
          />
          <div
            className="flex justify-center"
            style={{
              height: `calc(100% - ${headerHeight}px)`,
              overflow: "hidden",
            }}
          >
            <div
              className="flex-1 overflow-y-auto"
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                padding: "1rem 4rem 1rem 4rem",
                height: "100%",
              }}
            >
              {chatTileContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
