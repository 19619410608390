import React from "react";
import { ConnectionState } from "livekit-client";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ChatMessageType } from "../chat/ChatTile";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import config from "../../url_config/urls_config.js";
import ConfirmationPopup from "./ConfirmationPopup.tsx";
import EarlyEndSessionPopup from "./EarlyEndSessionPopup.tsx";
import ExtendTimePopup from "./ExtendTimePopup.tsx";
import LoadingSpinner from "../Common/Loadingspinner.jsx";

interface TimerResponse {
  timer: number;
}

interface PlaygroundHeaderProps {
  collaboratorModeActive?: boolean;
  logo?: ReactNode;
  title?: ReactNode;
  githubLink?: string;
  height: number;
  accentColor: string;
  connectionState: ConnectionState;
  chat?: any;
  messages: ChatMessageType[];
  isAgentConnected: boolean;
  autoConnect: () => void;
  onConnectClicked: () => void;
  onCloseRoom?: (remainingTime: number | null) => void;
  remainingTime?: number;
  isMobile: boolean;
  collaboratorMode: string;
}

export const PlaygroundHeader = ({
  accentColor,
  height,
  connectionState,
  autoConnect,
  chat,
  messages,
  isAgentConnected,
  onConnectClicked,
  onCloseRoom,
  remainingTime,
  isMobile,
  collaboratorMode,
}: PlaygroundHeaderProps) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = React.useState<number | null>(
    remainingTime !== undefined ? remainingTime : 600
  );
  const disconnectButtonRef = React.useRef<HTMLButtonElement>(null);
  const BASE_URL = config.getRq_api();
  const [popupStates, setPopupStates] = React.useState({
    isPopupVisible: false,
    isEarlyEndPopupVisible: false,
    isExtendTimePopupVisible: false,
    hasCheckedExtendedTime: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      @keyframes borderBlink {
        0% {
          border-color: #d1d5db;
        }
        50% {
          border-color: #C70003;
        }
        100% {
          border-color: #d1d5db;
        }
      }

      .blink-warning {
        animation: borderBlink 1s infinite;
        border-width: 1px;
      }
    `;
    document.head.appendChild(style);

    // Cleanup
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  React.useEffect(() => {
    const fetchTimer = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in local storage");
        return;
      }
      var timer_type = "timer"
      if(collaboratorMode == "slidenarrator"){
        timer_type = "narration_timer"
      }
      try {
        const response = await fetch(
          `${BASE_URL}/api/timer?token=${token}&timer_type=${timer_type}`
        );
        if (!response.ok) {
          setTimeLeft(600);
          throw new Error("Failed to fetch timer");
        }
        const data: TimerResponse = await response.json();
        const time = data.timer; // Ensure `time` is a number
        console.log("timer type", typeof time);
        setTimeLeft(time);
        setTimeout(() => {
          console.log("This will run after 3 seconds");
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTimer();
  }, []);

  React.useEffect(() => {
    if (connectionState !== ConnectionState.Connected && isAgentConnected) {
      autoConnect();
    }
  }, [connectionState, isAgentConnected, autoConnect]);

  React.useEffect(() => {
    if (timeLeft === null || popupStates.hasCheckedExtendedTime) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev !== null && prev > 0) {
          return prev - 1;
        } else if (prev === 0) {
          handleCheckExtendedTime();
          setPopupStates((prev) => ({ ...prev, hasCheckedExtendedTime: true }));
          return 0;
        }
        return 0;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft, popupStates.hasCheckedExtendedTime]);

  const handleCheckExtendedTime = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    setIsLoading(true);

    try {
      const response = await fetch(
        `${BASE_URL}/api/timer/check-extended-time`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      if (!data.extendedTime.extended_time) {
        setPopupStates((prev) => ({ ...prev, isExtendTimePopupVisible: true }));
      } else {
        handleEndSession();
      }
    } catch (error) {
      console.error("Error checking extended time:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExtendTime = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;
    console.log("timer type", collaboratorMode);
    var timer_type = "timer"
    var room_name = "room_name"
    if(collaboratorMode == "slidenarrator"){
      timer_type = "narration_timer"
      room_name = "narration_room_name"
    }
    const requestBody = {
      token,
      time_to_subtract: 120, // Ensure this is included
      timer_type: {timer_type},
      room_name: localStorage.getItem(room_name),
    };

    console.log("Request Body:", requestBody); // Log the request body for debugging
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/timer/set-to-120`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody), // Send the request body
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error Response:", errorData); // Log the error response
        throw new Error("Failed to extend time");
      }

      const data = await response.json();
      setTimeLeft((prev) => (prev !== null ? prev + 120 : 120));
      setPopupStates((prev) => ({ ...prev, hasCheckedExtendedTime: false }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleEndSession = async () => {
    const token = localStorage.getItem("token");
    if (!token || timeLeft === null) return;

    var timer_type = "timer"
    var room_name = "room_name"
    if(collaboratorMode == "slidenarrator"){
      timer_type = "narration_timer"
      room_name = "narration_room_name"
    }

    setIsLoading(true);

    // Navigate to the dashboard immediately
    onCloseRoom && onCloseRoom(timeLeft);

    // Initiate the API call in the background
    try {
      const response = await fetch(`${BASE_URL}/api/timer/subtract`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Token in the header
        },
        body: JSON.stringify({
          token, // Include the token in the body
          time_to_subtract: Number(timeLeft), // Include time_to_subtract in the body
          timer_type: "timer",
          room_name: localStorage.getItem(room_name),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error Response:", errorData); // Log the error response
        throw new Error("Failed to subtract time before refresh");
      }
    } catch (error) {
      console.error("Error ending session:", error); // Log any errors
    } finally {
      setIsLoading(false);
      navigate("/dashboard");
    }
  };

  const handleEndSessionClick = () => {
    if (timeLeft !== null && timeLeft > 480) {
      setPopupStates((prev) => ({ ...prev, isEarlyEndPopupVisible: true }));
    } else {
      setPopupStates((prev) => ({ ...prev, isPopupVisible: true }));
    }
  };

  const timerClassName = `flex items-center gap-2 border rounded px-4 py-3 mx-4 mt-10 ${
    timeLeft !== null && timeLeft <= 120
      ? "blink-warning"
      : "border-gray-[#6B6B6B]"
  }`;

  const formatTime = (timeLeft: number, isMobile: boolean) => {
    const minutes = Math.floor(timeLeft / 60);
    const secs = timeLeft % 60;
    if (isMobile) {
      return `${minutes}:${secs < 10 ? "0" : ""}${secs} min`;
    } else {
      return `${minutes}:${secs < 10 ? "0" : ""}${secs} min left`;
    }
  };

  // Add a useEffect to handle page unload
  React.useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      const token = localStorage.getItem("token");
      if (!token || timeLeft === null) return;

      try {
        const response = await fetch(`${BASE_URL}/api/timer/subtract`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, time_to_subtract: Number(timeLeft) }),
        });

        if (!response.ok) {
          throw new Error("Failed to subtract time before refresh");
        }
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [timeLeft]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div
        className={`flex justify-between items-center shrink-0 w-full ${
          isMobile ? "px-2" : ""
        }`}
        style={{
          height: height + "px",
          backgroundColor: `var(--${accentColor}-500)`,
        }}
      >
        <div className="flex items-center relative">
          {popupStates.isPopupVisible && (
            <ConfirmationPopup
              onConfirm={handleEndSession}
              onCancel={() =>
                setPopupStates((prev) => ({ ...prev, isPopupVisible: false }))
              }
            />
          )}

          {popupStates.isEarlyEndPopupVisible && (
            <EarlyEndSessionPopup
              onConfirm={() =>
                setPopupStates((prev) => ({
                  ...prev,
                  isEarlyEndPopupVisible: false,
                }))
              }
              onCancel={handleEndSession}
              feedback={false}
            />
          )}

          {popupStates.isExtendTimePopupVisible && (
            <ExtendTimePopup
              onExtend={() => {
                handleExtendTime();
                setPopupStates((prev) => ({
                  ...prev,
                  isExtendTimePopupVisible: false,
                }));
              }}
              onCancel={handleEndSession}
            />
          )}
        </div>

        <div
          className={`flex ${
            isMobile
              ? "w-full justify-between items-center pb-4 py-2"
              : "items-center relative mr-10"
          }`}
        >
          <div
            className={`flex items-center gap-1 border rounded ${
              isMobile
                ? "px-1 py-1 mx-1 text-xs mt-4 h-[28px] min-w-[0px]"
                : "px-4 py-3 mx-4 mt-10"
            } ${
              timeLeft !== null && timeLeft <= 120
                ? "blink-warning"
                : "border-gray-[#6B6B6B]"
            }`}
            style={{
              color:
                timeLeft !== null && timeLeft <= 120 ? "#C70003" : "inherit",
            }}
          >
            <AccessTimeIcon className={isMobile ? "w-4 h-4" : ""} />
            <span className={isMobile ? "text-xs" : ""}>
              {timeLeft !== null
                ? formatTime(timeLeft, isMobile)
                : "Loading..."}
            </span>
          </div>

          {timeLeft !== null && timeLeft <= 120 && (
            <div
              className={`absolute -bottom-5 left-1/2 -translate-x-1/2 whitespace-nowrap text-[#C70003] font-bold text-[10px] ${
                isMobile ? "hidden" : ""
              }`}
            >
              ⚠️ Time is running out!
            </div>
          )}

          <button
            onClick={handleEndSessionClick}
            className={`flex items-center justify-center border border-red-500 text-[#C70003] rounded
              ${
                isMobile
                  ? "mx-3 px-1 py-1 text-xs mt-4 h-[28px] min-w-[0px]"
                  : "px-4 py-3 mx-4 mt-10"
              }`}
          >
            {connectionState === ConnectionState.Connected && (
              <div className="text-red">End Session</div>
            )}
          </button>
        </div>
      </div>
    </>
  );
};
