import React, { useEffect, useCallback, useState } from "react";
import {
  LiveKitRoom,
  RoomAudioRenderer,
  StartAudio,
} from "@livekit/components-react";
import Playground from "../components/playground/Playground.tsx";
import { ConfigProvider } from "../hooks/useConfig.tsx";
import {
  ConnectionMode,
  ConnectionProvider,
  useConnection,
} from "../hooks/useConnection.tsx";
import "@livekit/components-styles";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../url_config/urls_config.js";
import axios from "axios";
import FeedbackPlayground from "../components/playground/FeedbackPlaygorund.tsx";

const caiBaseUrl = config.getCaiBaseUrl();
const livekitWssUrl = config.getLivekitWssUrl();
const BASE_URL = config.getRq_api();

export interface HomeInnerProps {
  collaboratorMode: string;
  projectid: number;
  remainingTime: number;
  setIsDeckModalOpen: (isOpen: boolean) => void;
}

export default function LiveKit({projectId, usecaseType, setIsDeckModalOpen }) {
  const location = useLocation();
  const collaborateMode = (location.state?.usecaseType || usecaseType) ?? "default"; // Defaults to default
  const projectid = (location.state?.projectid || projectId) ?? null; // Defaults to default
  const remainingTime = location.state?.remainingTime ?? 0; // Default to 0
  const isCompact = usecaseType === "feedback" || usecaseType === "slidenarrator";
  return (
    <ConfigProvider>
      <ConnectionProvider>
        <div className={`flex flex-col md:flex-row ${isCompact ? "h-full": "h-screen"}`}>
          <HomeInner
            collaboratorMode={collaborateMode}
            remainingTime={remainingTime}
            projectid={projectid}
            setIsDeckModalOpen={setIsDeckModalOpen}
          />
        </div>
      </ConnectionProvider>
    </ConfigProvider>
  );
}

export function HomeInner({
  collaboratorMode,
  remainingTime,
  projectid,
  setIsDeckModalOpen
}: HomeInnerProps) {
  const [conversations, setConversations] = useState([]);
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    loading: false,
  });
  const name = localStorage.getItem("name");
  // const [room_name, setConvRoomName] = useState("");
  const { shouldConnect, mode, connect, disconnect } = useConnection();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [resume_session, setResumeSession] = useState(false);
  const [isRoomClosing, setIsRoomClosing] = useState(false);
  const [hasAttemptedConnection, setHasAttemptedConnection] = useState(false);
  const secret_code = config.getUsecaseID(collaboratorMode);
  console.log("this is collaborate mode ", collaboratorMode, secret_code);
  const [room_name, setConvRoomName] = useState(() => {
    // Check the `collaboratorMode` condition when initializing the state
    switch (collaboratorMode) {
      case "default":
        return localStorage.getItem("room_name") || ""; // Default room name
      case "slidenarrator":
        return localStorage.getItem("narration_room_name") || ""; // SlideNarrator room name
      case "feedback":
        console.log("feedback room choosed");
        return localStorage.getItem("feedback_room") || ""; // Feedback room name
      default:
        throw new Error(`Invalid collaboratorMode: ${collaboratorMode}`);
    }
  });

  const fetchTranscription = async () => {
    setLoadingState((prev) => ({ ...prev, isLoading: true }));
    try {
      const response = await axios.get(
        `${caiBaseUrl}/session/${room_name}/transcript/`
      );
      setConversations(response.data.conversations);
    } catch (error) {
    } finally {
      setLoadingState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  // Now, useEffect to trigger fetchTranscription after room_name is set
  useEffect(() => {
    // Check if room_name is set
    fetchTranscription(); // Call fetchTranscription only after room_name is updated
  }, []); // Runs when room_name changes

  React.useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      if (token === null) return;
      setToken(null);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [setToken]);

  const handleConnect = useCallback(
    async (c: boolean, mode: ConnectionMode) => {
      c ? connect(mode) : disconnect();
    },
    [connect, disconnect]
  );

  const connectToRoom = useCallback(async () => {
    if (token || loadingState.loading || hasAttemptedConnection) return;
    setLoadingState((prev) => ({ ...prev, loading: true }));
    setHasAttemptedConnection(true);
    try {
      var requestBody = {};
      if (collaboratorMode!= "default"){
        var param = "";
        if (collaboratorMode== "slidenarrator"){
          param = projectid.toString();
        }
        else{
          param = room_name;
        }
        const response = await axios.get(`${BASE_URL}/api/projects/${param}/prd/${collaboratorMode}`);
        const dynamic_content = response.data.requirements
        requestBody = {
          room_name,
          name,
          secret_code,
          resume_session,
          dynamic_content
        };
      }
      else{
        requestBody = {
          room_name,
          name,
          secret_code,
          resume_session,
        };
      }
      const response = await fetch(`${caiBaseUrl}/getToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.status === 403) {
        alert("Incorrect secret key. Please try again");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch token");
      }

      const data = await response.json();
      setToken(data.token);
      console.log(data.token);
      handleConnect(true, process.env.LIVEKIT_WSS_URL ? "env" : mode);
    } catch (error) {
      console.error("Error fetching token", error);
    } finally {
      setLoadingState((prev) => ({ ...prev, loading: false }));
    }
  }, [
    token,
    loadingState.loading,
    name,
    room_name,
    handleConnect,
    mode,
    navigate,
  ]);

  useEffect(() => {
    if (!token) {
      connectToRoom();
    }
  }, [connectToRoom, token]);

  const deleteRoom = async (room_name) => {
    try {
      const response = await axios.delete(`${caiBaseUrl}/room/${room_name}`);
      if (response.status === 200) {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting room:", error);
    }
  };

  const handleCloseRoom = useCallback(
    async (remainingTime: number | null) => {
      try {
        await disconnect();
        localStorage.removeItem('sp');
        // make a post axios call to /create-project to create a project => room_name, timer
        const response = await axios.post(`${BASE_URL}/api/create-project`, {
          room_name: room_name,
          timer: remainingTime,
        });

        if (remainingTime !== null) {
          if (response.data.status == 200) {
            if(collaboratorMode == "default"){
              var regenerate_prd = true;
            if (remainingTime > 480) {
              regenerate_prd = false;
            }

            console.log("navigated to dashboard with" ,regenerate_prd, response.data.project_id,response.data.new,);
              navigate("/dashboard", {
                state: {
                  regenerate_prd: regenerate_prd,
                  remainingTime,
                  project_id: response.data.project_id,
                  first: response.data.new,
                },
              });
            
          }
          else {
            setIsDeckModalOpen(false);
          }
        }
        }
      } catch (error) {
        console.error("Error closing room:", error);
      } finally {
        setToken(null);
      }
    },
    [disconnect, navigate]
  );
  const isCompact = collaboratorMode === "feedback" || collaboratorMode === "slidenarrator";

  return (
    <main className={`relative flex flex-col justify-center items-center ${isCompact ? "h-full": "h-screen"} w-full bg-white`}>
      {token === null || isRoomClosing ? (
        <div style={{ color: "white" }}>Connecting...</div>
      ) : (
        <LiveKitRoom
          className={`flex flex-col h-full w-full`}
          serverUrl={livekitWssUrl}
          token={token}
          connect={true}
          audio={{
            autoGainControl: true,
            echoCancellation: true,
            noiseSuppression: true,
            voiceIsolation: true,
            channelCount: 1,
            sampleRate: 44100,
            latency: 0.05,
          }}
        >
          {collaboratorMode !== "default" ? (
            <FeedbackPlayground
              collaboratorModeActive={collaboratorMode}
              themeColors={["cyan"]}
              onConnect={(c) => {
                const m = process.env.LIVEKIT_WSS_URL ? "env" : mode;
                handleConnect(c, m);
              }}
              chat={conversations}
              onCloseRoom={handleCloseRoom}
              remainingTime={remainingTime}
              project_id={projectid}
            />
          ) : (
            <Playground
              themeColors={["cyan"]}
              onConnect={(c) => {
                const m = process.env.LIVEKIT_WSS_URL ? "env" : mode;
                handleConnect(c, m);
              }}
              chat={conversations}
              onCloseRoom={handleCloseRoom}
              remainingTime={remainingTime}
              collaboratorMode={collaboratorMode}
            />
          )}

          <RoomAudioRenderer />
          <StartAudio
            label="Click to enable audio playback"
            data-audio-controlled="true"
          />
        </LiveKitRoom>
      )}
    </main>
  );
}
