import React, { useState } from "react";

interface EarlyEndSessionPopupProps {
  feedback: boolean; // feedback prop to conditionally render the message
  onConfirm: () => void;
  onCancel: () => void;
}

const EarlyEndSessionPopup: React.FC<EarlyEndSessionPopupProps> = ({
  feedback = false,
  onConfirm,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } catch (error) {
      console.error("Error during confirmation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 text-center z-50 ">
      <div
        className="bg-[#ffffff] p-5 rounded-xl shadow-lg "
        style={{ height: "350px", width: "700px" }}
      >
        <h2 className="text-3xl font-bold text-center mt-10">
          Conversation Time Limit
        </h2>
        <p className="mb-4 text-xl mt-2">
          {feedback
            ? "Are you sure you want to end the slide narration?"
            : "Your conversation is too short to generate a PRD. Please extend the conversation"}
        </p>
        <div className="flex justify-center mt-2">
          <button
            onClick={onCancel}
            className="border border-gray-500 text-gray mx-4 mt-10 px-10 py-2 rounded "
          >
            End Anyways
          </button>
          <button
            onClick={handleConfirm}
            className={`border bg-[#0060C7] text-white mx-4 mt-10 px-10 py-2 rounded ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Processing..." : "Continue Conversation"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EarlyEndSessionPopup;
