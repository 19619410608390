import React from "react";

interface ConfirmationPopupProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  onConfirm,
  onCancel,
}) => {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-[#ffffff] p-5 rounded-xl shadow-lg  "
        style={{ height: "350px", width: "700px" }}
      >
        <h2 className="text-3xl font-bold text-center mt-10">
          Heading Out Already?
        </h2>
        <p className="text-center text-2xl mt-5">
          Are you sure you want to end the session?
        </p>
        <div className="flex justify-center mt-2">
          <button
            onClick={handleConfirm}
            className=" border border-red-500 text-[#C70003] mx-4 mt-10 px-10 py-2 rounded text-red z-50"
          >
            End Session
          </button>
          <button
            onClick={onCancel}
            className=" border bg-[#0060C7] text-white mx-4 mt-10 px-10 py-2 rounded"
          >
            Stay in Session
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
